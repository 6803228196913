import { Component } from 'react';
import "./BookingDetailsWidget.scss"
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import { Icons } from '../../../../Common/AllsvgIcons';
import { DateTime } from 'luxon';
import { appContext } from '../../../../AppContext';

class BookingDetailsWidget extends Component<IProps, IState>
{

    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
        };
    };

    public render(): JSX.Element
    {
        return (
            <div className='bookingDetailsContainer'>
                {
                    this.props.spaceImageUrl && this.props.spaceImageUrl.length > 0 ?
                        <img style={{ width: 75, height: 75, borderRadius: 4 }} src={this.props.spaceImageUrl} />
                        :
                        <div style={{ width: 75, height: 75, borderRadius: 4, background: 'grey' }}></div>
                }
                <div style={{ flex: '1 1 0', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 8, display: 'inline-flex' }}>
                    <div style={{ fontWeight: '600' }}>{this.props.spaceName}</div>
                    <div style={{ justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex' }}>
                        <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'flex' }}>
                            <div style={{ fontSize: 17, height: 18 }}>
                                <IbssSvgIcon fontSize='inherit' >
                                    {Icons.calenderIcon}
                                </IbssSvgIcon>
                            </div>
                            <div className='font-subheading'>{this.props.start.toLocaleString(DateTime.DATE_SHORT)}</div>
                        </div>
                        <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 4, display: 'flex' }}>
                            <div style={{ fontSize: 17, height: 15 }}>
                                <IbssSvgIcon fontSize='inherit' >
                                    {Icons.TimeIcon}
                                </IbssSvgIcon>
                            </div>
                            <div className='font-subheading'>{this.props.start.toLocaleString(DateTime.TIME_24_SIMPLE)} - {this.props.end.toLocaleString(DateTime.TIME_SIMPLE)}</div>
                        </div>
                    </div>
                    <div style={{ justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex' }}>
                        <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 10, display: 'flex' }}>
                            <div style={{ fontSize: 11, height: 15 }}>
                                <IbssSvgIcon fontSize='inherit' >
                                    {Icons.UserIcon}
                                </IbssSvgIcon>
                            </div>
                            {
                                this.props.host != "" &&
                                <div>
                                    <div className='font-subheading'>{this.labels.HubLabelHost}: {this.props.host}</div>
                                    <div className='font-subheading'>•</div>
                                </div>
                            }
                            <div className='font-subheading'>{this.labels.HubLabelOwner}: {this.props.owner}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BookingDetailsWidget;

export interface IProps
{
    spaceName: string;
    spaceImageUrl: string;
    start: DateTime;
    end: DateTime;
    host: string;
    owner: string;
}

export interface IState
{
}