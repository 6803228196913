import { Component, ReactElement } from "react";
import { appContext } from "../../../../AppContext";
import { Card, Grid, Divider, Typography, Box, Skeleton } from "@mui/material";
import "../../../../styles/css/dashboard.scss";
import "./cateringOrder.scss";
import IbssButton from "../../../../Components/Buttons/Button/IbssButton";
import IbssSvgIcon from "../../../../Components/Icons/SvgIcon/IbssSvgIcon";
import { Icons } from "../../../../Common/AllsvgIcons";
import IbssIconButton from "../../../../Components/Buttons/IconButton/IbssIconButton";
import { RouteComponentProps } from "react-router-dom";
import { DateTime } from "luxon";
import { ICostCodeAllocation } from "../../../../Providers.Api/Tasks/CreateTaskEndpoint";
import { IPropsFromState } from "../../../../redux/Interfaces";
import { ICateringOrder, IMenuItem } from "../../../../Providers.Api/CateringOrders/GetCateringOrderEndpoint";
import { INode, PagedResponse } from "../../../../Providers.Api/Models";
import { Modal } from "react-bootstrap";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Space } from "../../../../Providers.Api/Spaces/SpaceRepository";
import IbssHorizontalTabs, { TabsParameters } from "../../../../Components/Layout/Tabs/IbssTabs";
import IbssAccordion, { IAccordion } from "../../../../Components/Miscellaneous/Accordion/IbssAccordion";
import { ICreateCateringOrder } from "../../../../Providers.Api/CateringOrders/CreateCateringOrderEndpoint";
import IbssDialog from "../../../../Components/Dialogs/BaseDialog/IbssDialog";
import IbssCheckBox from "../../../../Components/Inputs/CheckBox/IbssCheckBox";
import { Classification, Status } from "../../../../Providers.Api/CateringItems/CateringItemRepository";
import { IGetV2BookingResponse } from "../../../../Providers.Api/Bookings/GetV2BookingEndpoint";
import { ICateringOrderPolicy } from "../../../../Providers.Api/CateringOrderPolicies/CateringOrderPoliciesRepository";
import CostCodesDialog, { CostCodeWithAllocation } from "../../../../Components/Dialogs/CostCodesDialog/CostCodesDialog";
import { StaticContext } from "react-router";
import { CateringMenuStatus, Filter, IMenuItems } from "../../../../Providers.Api/CateringMenus/CateringMenuRepository";
import LoadingOverlay from "../../../../Components/Navigation/LoadingOverlay/LoadingOverlay";
import { ICateringMenu } from "../../../../Providers.Api/CateringMenus/GetByIdEndpoint";
import { IbssPage } from "../../../../Components/Core/BasePage/IbssPage";
import { DateHelper } from "../../../../Common/DateHelper";
import OrderSummaryWidget from "./OrderSummaryWidget";
import AddNewOrderWidget from "./AddNewOrderWidget";
import BookingDetailsWidget from "./BookingDetailsWidget";
import OrderSummaryPanel from "./OrderSummaryPanel";
import { CateringOrder } from "./DataModels";
import UnsavedChangesPopup from "./UnsavedChangesPopup";
import { IDefaultCostCodes } from "../../../../Providers.Api/UserPreferences/UserPreferenceRepository";

export default class EditCateringOrder extends IbssPage<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get apiClient() { return appContext().apiClient; }
    private get localStorage() { return appContext().localStorageProvider; }
    private get appState() { return appContext().state; }
    private get bookingService() { return appContext().bookingService; }
    private get apiCache() { return appContext().apiCache; }
    private buildingId: number;
    private hasMultipleOrdersRights: boolean;
    private isOneLens: boolean;

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            originalOrder: new OrderView(),
            order: new OrderView(),
            allCateringOrdersForBooking: [],
            selectedOrderId: '',
            orderItems: [],
            loading: false,
            currency: '',
            preBookCateringTime: '',
            showConfirmationModal: false,
            floorId: 0,
            numOfAttendees: 0,
            spaces: [],
            showCostCodeModal: false,
            lightModeTheme: this.appState.lightModeTheme,
            costCodes: [],
            menu: {
                menuId: '',
                name: ''
            },
            cateringRestrictions: [],
            selectedRestrictions: [],
            selectedRestrictionsEdit: [],
            showRestrictionsModal: false,
            selectedAllergenRestrictionsFilter: [],
            selectedAllergenRestrictionsFilterEdit: [],
            selectedDietaryRestrictionsFilterEdit: [],
            selectedDietaryRestrictionsFilter: [],
            showFilterModal: false,
            itemOrderPolicies: [],
            skeletonLoad: false,
            showStatusUpdateConfirmation: '',
            resetCateringStatus: false,
            availableCateringMenusView: [],
            showCateringAddedPopup: false,
            bookingData: {} as IGetV2BookingResponse,
            orderPolicies: [],
            availableCateringMenus: [],
            unsavedChanges: false,
            showUnsavedChangesPopup: false,
            attemptedOrderIdToSelect: ''
        };
        this.buildingId = parseInt(this.props.match.params.buildingid);
        this.hasMultipleOrdersRights = this.localStorage.permissionAppliesToBuilding('API.Catering.MultipleOrders', this.buildingId);
        this.isOneLens = window.location.href.includes('operational-services-catering');
    }

    public async componentDidMount(): Promise<void>
    {
        if (!this.isOneLens)
        {
            this.pageTitle = this.labels.HubMenumySchedule;
        }
        this.appState.autoMap<IState>(this, i => ({ lightModeTheme: i.lightModeTheme }));
        const { match } = this.props;
        this.setState({ skeletonLoad: true });
        const spaces = await this.apiCache.getSpacesByBuilding(this.buildingId);
        const cateringOrdersForBooking = await this.getCateringOrdersForBooking();
        this.setState({ allCateringOrdersForBooking: cateringOrdersForBooking.value.map(x => OrderView.fromApiTask({ ...x, Menu_Items: JSON.parse(x.Menu_Items) })) });
        let orderData = cateringOrdersForBooking.value.length > 0 ? cateringOrdersForBooking.value[0] : null;
        if (match.params.cateringOrderId)
        {
            orderData = cateringOrdersForBooking.value.filter(x => x.Order_Id == match.params.cateringOrderId)[0]
        }
        this.setState({ selectedOrderId: orderData ? orderData.Order_Id : '' })
        const order = (orderData == null ? null : OrderView.fromApiTask({ ...orderData, Menu_Items: JSON.parse(orderData.Menu_Items) }));
        const floorId = order?.nodeId ?? spaces.filter(space => space.Space_Id == match.params.spaceId)[0].Node_Id;
        const bookingId = order?.bookingId ?? match.params.bookingid;
        let menuId = order?.menuItems[0]?.menuId ?? null;
        const bookingData = await this.bookingService.get(floorId, bookingId);
        const orderPolicy = await this.apiClient.cateringOrderPolicies.getMany(this.buildingId);
        this.setState({ bookingData: bookingData, orderPolicies: orderPolicy });
        let cateringMenu = null

        try
        {
            const currentUsersRoles = (match.params.cateringOrderId ? null : await this.apiClient.roles.getRolesByCurrentUser());
            const currentUsersRoleIds = currentUsersRoles?.map(i => i.id) ?? null;
            const cateringMenus = await this.apiClient.cateringMenus.getMany(
                this.buildingId,
                true,
                true,
                100,
                new Filter({
                    status: CateringMenuStatus.active,
                    availableFrom: DateTime.fromISO(bookingData.Booking_Start),
                    availableTo: DateTime.fromISO(bookingData.Booking_End),
                    roleIds: currentUsersRoleIds ?? undefined,
                    spaceId: bookingData.Space_Id
                }));
            this.setState({
                availableCateringMenusView: cateringMenus.value.map(menu => MenuView.fromApiModel(menu, null, this.isOneLens, bookingData, orderPolicy)),
                availableCateringMenus: cateringMenus.value
            });
            cateringMenu = cateringMenus.value[0] ?? null;
        } catch (error)
        {
            cateringMenu = null;
        }

        // If an existing order is being edited then get the menu that order was created against
        if (menuId != null && cateringMenu == null)
        {
            try
            {
                cateringMenu = await this.apiClient.cateringMenus.getById(this.buildingId, menuId, true, true);
            } catch (error)
            {
                cateringMenu = null;
            }
        }

        if (order == null)
        {
            this.setState({
                order: new OrderView({
                    ...this.state.order,
                    cateringServiceTime: DateHelper.fromIsoByNode(bookingData.Booking_Start, bookingData.Node_Id),
                    cateringClearingTime: DateHelper.fromIsoByNode(bookingData.Booking_End, bookingData.Node_Id),
                })
            });
        }

        if (order != null)
        {
            this.setState({
                order: order,
                originalOrder: order,
                selectedRestrictions: order.cateringOrderRestrictions,
            });
        }

        if (cateringMenu != null)
        {
            const menuView = MenuView.fromApiModel(cateringMenu, order, this.isOneLens, bookingData, orderPolicy)
            this.setState({
                menu: menuView.menu,
                orderItems: menuView.orderItems,
            })
        }

        const cateringRestrictions = (await this.apiClient.cateringRestrictions.getCateringRestrictions(this.buildingId)).Value;
        const filteredCateringRestrictions = cateringRestrictions.filter(x => x.Section == 'Allergen' || x.Section == 'Dietary');

        this.setState({
            floorId: floorId,
            cateringRestrictions: filteredCateringRestrictions.map(x => ({ id: x.Restriction_Id ?? '', section: x.Section, imageURI: x.ImageURI, name: x.Name })),
            spaces: spaces
        });

        const availableSpaces = this.localStorage.getNodeData();
        const buildings = availableSpaces.Regions.map(x => x.Buildings);
        let selectedBuilding: INode | null = null;

        for (let i = 0; i < buildings.length; i++)
        {
            const building = buildings[i].filter(building => building.Node_Id === this.buildingId);
            if (building.length > 0)
            {
                selectedBuilding = building[0];
                break;
            }
        }
        if (selectedBuilding == null)
        {
            return;
        }
        this.setState({ currency: selectedBuilding.Cat_Crncy, preBookCateringTime: selectedBuilding.Pre_Book_Cat_Time });

        await this.loadTaskCostCodes();
        if (this.props.history.location.pathname.includes('create-catering-order') && this.state.costCodes.length === 0)
        {
            // if catering task is being created and it has no cost codes assigned, try to get userPreference default catering cost codes
            await this.getDefaultCateringCostCodes();
        }
        this.setState({ skeletonLoad: false });
    }

    private async getDefaultCateringCostCodes(): Promise<void>
    {
        const { DefaultCateringCostCodes } = this.localStorage.getUserPreferences();

        if (DefaultCateringCostCodes.length === 0)
        {
            return;
        }

        const defaultCateringCostCodes = DefaultCateringCostCodes.map(costCode => CostAllocationView.fromUserPrefDefaultCateringCostCodes(costCode));

        await this.setStateAsync({ costCodes: defaultCateringCostCodes });
    }

    private getCostCodeAllocationLabel(): string
    {
        // if there an no allocated cost codes to task AND there is no userPreference defaultCateringCostCode, label is 'Add +';
        return this.state.order.costCodeAllocation.length == 0 && this.state.costCodes.length === 0 ? 'Add +' : this.state.costCodes.map(x => x.costCode).join(', ');
    }

    private async getCateringOrdersForBooking(): Promise<PagedResponse<CateringOrder[]>>
    {
        const cateringOrdersForBooking = await appContext().ibssApiClientV2.v2.byNodeid.cateringOrder.get<PagedResponse<CateringOrder[]>>({
            nodeId: this.buildingId,
            filter: `Booking_Id eq '${this.props.match.params.bookingid}'`,
            recursive: true,
            top: 20,
            select: CateringOrder,
        });
        return cateringOrdersForBooking;
    }

    private itemQuantityChanged(itemId: string, type: 'remove' | 'add'): void
    {
        const objIndex = this.state.orderItems.findIndex(x => x.menuItemId == itemId);
        let orderItems = this.state.orderItems;

        if (type == 'remove' && orderItems[objIndex].quantity != 0)
        {
            orderItems[objIndex].quantity = orderItems[objIndex].quantity - 1;
        }
        if (type == 'add')
        {
            orderItems[objIndex].quantity = orderItems[objIndex].quantity + 1;
        }
        this.setState({ orderItems: orderItems, resetCateringStatus: true, unsavedChanges: true });

    }

    private async updateOrder(): Promise<void>
    {
        this.setState({ loading: true })

        const orderTotal = this.state.orderItems?.map(x => x.unitPrice * x.quantity).reduce((x, y) => { return x + y }, 0).toFixed(2);

        const orderItems = this.state.orderItems
            .filter(item => item.quantity > 0).map(item => { return ({ Id: item.menuItemId, Menu_Id: this.state.menu.menuId, Description: item.description, QuantityOfItems: item.quantity }) });

        const costCodeAllocations = this.state.costCodes
            .map(i => ({ Cost_Code: i.costCode, Cost_Code_Id: i.costCodeId, Allocation: i.allocation }));

        const payload = this.state.order.toApiOrder(
            this.state.order.spaceId,
            this.state.bookingData.Booking_Id,
            this.state.floorId,
            this.state.numOfAttendees,
            JSON.stringify(orderItems),
            costCodeAllocations,
            parseFloat(orderTotal),
            this.state.selectedRestrictions,
            this.state.resetCateringStatus ? 'PendingApproval' : this.state.order.cateringStatus,
            this.state.order.cateringServiceTime.toUtcByNode(this.state.floorId).toISO(),
            this.state.order.cateringClearingTime.toUtcByNode(this.state.floorId).toISO()
        );

        try
        {
            await this.apiClient.cateringOrders.editCateringOrder(this.state.floorId, this.state.order.orderId, payload);
            const cateringOrdersForBooking = await this.getCateringOrdersForBooking();
            this.setState({ unsavedChanges: false, allCateringOrdersForBooking: cateringOrdersForBooking.value.map(x => OrderView.fromApiTask({ ...x, Menu_Items: JSON.parse(x.Menu_Items) })), loading: false, showConfirmationModal: false });
        } catch (error)
        {
            this.setState({ loading: false })
        }
    }

    private cancelChanges(): void
    {
        const { match } = this.props
        const activeurl = window.location.href;

        if (this.props.location.state?.originRoute)
        {
            this.props.history.push(this.props.location.state?.originRoute);
        }
        else if (match.params.bookingid !== undefined || activeurl.includes("flex-my-bookings"))
        {
            this.props.history.goBack();
        }
        else
        {
            this.props.history.push("/catering-orders/" + this.buildingId);
        }
    }

    private async create(): Promise<void>
    {
        const { match } = this.props
        this.setState({ loading: true });
        const orderTotal = this.state.orderItems?.map(x => x.unitPrice * x.quantity).reduce((x, y) => { return x + y }, 0).toFixed(2);
        const orderItems = this.state.orderItems
            .filter(item => item.quantity > 0).map(item => { return ({ Id: item.menuItemId, Menu_Id: this.state.menu.menuId, Description: item.description, QuantityOfItems: item.quantity }) });
        const costCodeAllocations = this.state.costCodes
            .map(i => ({ Cost_Code: i.costCode, Cost_Code_Id: i.costCodeId, Allocation: i.allocation }));
        const payload = this.state.order.toApiOrder(
            this.state.bookingData.Space_Id,
            match.params.bookingid,
            this.state.floorId,
            this.state.numOfAttendees,
            JSON.stringify(orderItems),
            costCodeAllocations,
            parseFloat(orderTotal),
            this.state.selectedRestrictions,
            'PendingApproval',
            this.state.order.cateringServiceTime.toUtcByNode(this.state.floorId).toISO(),
            this.state.order.cateringClearingTime.toUtcByNode(this.state.floorId).toISO()
        );
        try
        {
            await this.apiClient.cateringOrders.createCateringOrder(this.state.floorId, payload);
            const cateringOrdersForBooking = await this.getCateringOrdersForBooking();
            this.setState({ unsavedChanges: false, allCateringOrdersForBooking: cateringOrdersForBooking.value.map(x => OrderView.fromApiTask({ ...x, Menu_Items: JSON.parse(x.Menu_Items) })), showCateringAddedPopup: true, loading: false });
        } catch (error)
        {
            this.setState({ loading: false })
        }
    }

    private handleCostCodeModal(): void
    {
        this.setState((prevState) => ({
            showCostCodeModal: !prevState.showCostCodeModal
        }));
    }

    private async updateBookingCostCodes(updatedCostCodes: CostCodeWithAllocation[]): Promise<void>
    {
        this.setState((prevState) =>
        {
            return {
                ...prevState,
                costCodes: updatedCostCodes,
                loading: true
            }
        });
        let i = 0;
        while (this.state.allCateringOrdersForBooking.length > i)
        {
            try
            {
                await appContext().ibssApiClientV2.v2.byNodeid.cateringOrder.byOrderId.patch<string>({
                    nodeId: this.state.bookingData.Node_Id,
                    orderId: this.state.allCateringOrdersForBooking[i].orderId,
                    body: { Cost_Code_Allocation: JSON.stringify(updatedCostCodes.map(i => ({ Cost_Code: i.costCode, Cost_Code_Id: i.costCodeId, Allocation: i.allocation }))) }
                });
            } catch (error)
            {

            }
            i = i + 1;
        }

        this.setState({ loading: false });

    }

    private async loadTaskCostCodes(): Promise<void>
    {
        const taskCostCodes: ICostAllocation[] = this.state.order.costCodeAllocation ?? [];

        this.setState({
            costCodes: taskCostCodes, //costs codes associated with task, which has to be displayed on this component.
        });
    }

    public handleChangeCateringServiceTime(date: Date | null): void
    {
        if (date !== null)
        {
            const cateringServiceTime = DateTime.fromJSDate(date);
            this.setState({ order: new OrderView({ ...this.state.order, cateringServiceTime: cateringServiceTime ?? '' }), resetCateringStatus: true, unsavedChanges: true });
        }
    }

    public handleChangeCateringClearingTime(date: Date | null): void
    {
        if (date !== null)
        {
            const cateringClearingTime = DateTime.fromJSDate(date);
            this.setState({ order: new OrderView({ ...this.state.order, cateringClearingTime: cateringClearingTime ?? '' }), resetCateringStatus: true, unsavedChanges: true });
        }
    }

    private restrictionCheckboxSelected(checked: boolean, restriction: ICateringRestrictionView): void
    {
        if (checked)
        {
            this.setState({ selectedRestrictionsEdit: [...this.state.selectedRestrictionsEdit, restriction] })
        }
        if (!checked)
        {
            const updatedItems = this.state.selectedRestrictionsEdit.filter(x => x.id !== restriction.id)
            this.setState({ selectedRestrictionsEdit: updatedItems })
        }
    }

    private displayRestrictionsModal(): void
    {
        this.setState({ showRestrictionsModal: true, selectedRestrictionsEdit: this.state.selectedRestrictions })
    }

    private closeRestrictionsModal(): void
    {
        this.setState({ showRestrictionsModal: false, selectedRestrictionsEdit: [] })
    }

    private applyRestrictionUpdated(): void
    {
        this.setState({ showRestrictionsModal: false, selectedRestrictions: this.state.selectedRestrictionsEdit, selectedRestrictionsEdit: [], unsavedChanges: true })
    }

    private displayFilterModal(): void
    {
        this.setState({ showFilterModal: true, selectedAllergenRestrictionsFilterEdit: this.state.selectedAllergenRestrictionsFilter, selectedDietaryRestrictionsFilterEdit: this.state.selectedDietaryRestrictionsFilter })
    }

    private closeFilterModal(): void
    {
        this.setState({ showFilterModal: false, selectedAllergenRestrictionsFilterEdit: [], selectedDietaryRestrictionsFilterEdit: [] })
    }

    private applyRestrictionFilterUpdated(): void
    {
        const updatedOrderItems = this.state.orderItems.map(x =>
        {
            const itemDietaryRestrictions = x.menuItemRestrictions.filter(restriction => restriction.section == 'Dietary');
            const itemAllergenRestrictions = x.menuItemRestrictions.filter(restriction => restriction.section == 'Allergen');

            let dietaryFilterRequirementsValid = true;
            for (let index = 0; index < this.state.selectedDietaryRestrictionsFilterEdit.length; index++)
            {
                if (this.state.selectedDietaryRestrictionsFilterEdit.length > 0 && !itemDietaryRestrictions.map(x => x.name).includes(this.state.selectedDietaryRestrictionsFilterEdit[index]))
                {
                    dietaryFilterRequirementsValid = false;
                }
            }
            if (this.state.selectedDietaryRestrictionsFilterEdit.length > 0 && itemDietaryRestrictions.length == 0)
            {
                dietaryFilterRequirementsValid = false;
            }

            let allergenRequirementsValid = true;
            for (let index = 0; index < itemAllergenRestrictions.length; index++)
            {
                if (this.state.selectedAllergenRestrictionsFilterEdit.length > 0 && this.state.selectedAllergenRestrictionsFilterEdit.includes(itemAllergenRestrictions[index].name))
                {
                    allergenRequirementsValid = false;
                }
            }

            return (
                {
                    ...x,
                    meetsFilterRequirements: dietaryFilterRequirementsValid && allergenRequirementsValid
                }
            )
        });
        this.setState({
            showFilterModal: false,
            selectedAllergenRestrictionsFilter: this.state.selectedAllergenRestrictionsFilterEdit,
            selectedAllergenRestrictionsFilterEdit: [],
            selectedDietaryRestrictionsFilter: this.state.selectedDietaryRestrictionsFilterEdit,
            selectedDietaryRestrictionsFilterEdit: [],
            orderItems: updatedOrderItems
        });
    }

    private allergenFilterCheckboxSelected(checked: boolean, restriction: string): void
    {

        if (checked)
        {
            this.setState({ selectedAllergenRestrictionsFilterEdit: [...this.state.selectedAllergenRestrictionsFilterEdit, restriction] })
        }
        if (!checked)
        {
            const updatedItems = this.state.selectedAllergenRestrictionsFilterEdit.filter(x => x !== restriction)
            this.setState({ selectedAllergenRestrictionsFilterEdit: updatedItems })
        }
    }

    private dietaryFilterCheckboxSelected(checked: boolean, restriction: string): void
    {

        if (checked)
        {
            this.setState({ selectedDietaryRestrictionsFilterEdit: [...this.state.selectedDietaryRestrictionsFilterEdit, restriction] })
        }
        if (!checked)
        {
            const updatedItems = this.state.selectedDietaryRestrictionsFilterEdit.filter(x => x !== restriction)
            this.setState({ selectedDietaryRestrictionsFilterEdit: updatedItems })
        }
    }

    private getClassificationLabel(classification: string): string
    {
        if (classification == 'MenuItemClassificationFood')
        {
            return this.labels.HubLabelFood
        }
        if (classification == 'MenuItemClassificationSnack')
        {
            return this.labels.HubLabelSnack
        }
        if (classification == 'MenuItemClassificationBeverage')
        {
            return this.labels.HubLabelBeverage
        }
        return '';
    }

    private async updateOrderStatus(status: string): Promise<void>
    {
        this.setState({ loading: true });
        try
        {
            await this.apiClient.cateringOrders.patchCateringOrder(this.state.order.nodeId, this.state.order.orderId, { Catering_Status: status });
            const cateringOrdersForBooking = await this.getCateringOrdersForBooking();
            this.setState({
                allCateringOrdersForBooking: cateringOrdersForBooking.value.map(x => OrderView.fromApiTask({ ...x, Menu_Items: JSON.parse(x.Menu_Items) })),
                order: cateringOrdersForBooking.value.map(x => OrderView.fromApiTask({ ...x, Menu_Items: JSON.parse(x.Menu_Items) })).filter(x => x.orderId == this.state.order.orderId)[0],
                loading: false,
                showStatusUpdateConfirmation: ''
            });
        } catch (error)
        {
            this.setState({ loading: false, showStatusUpdateConfirmation: '' });
        }
    }

    private menuChanged(menuId: string): void
    {
        const selectedMenu = this.state.availableCateringMenusView.filter(x => x.menu.menuId == menuId);
        const orderItems = selectedMenu[0].orderItems.map(item => ({ ...item, quantity: 0 }))

        this.setState({
            menu: selectedMenu[0].menu,
            orderItems: orderItems
        })
    }

    private getAccordionItems(classification: string): IAccordion[]
    {
        const availableItems = this.state.orderItems?.filter(x => x.classification == classification && x.availableToOrder && x.meetsFilterRequirements);

        return (
            availableItems.map(item =>
            {
                return (
                    {
                        key: item.menuItemId,
                        summary: (
                            <Grid container>
                                <Grid item xs={2} md={3} lg={2} sx={{ display: 'flex', justifyContent: 'center', padding: item.imageURI.length > 0 ? '' : '40px 0px' }}>
                                    {item.imageURI.length > 0 &&
                                        <img
                                            width={100}
                                            height={100}
                                            src={item.imageURI}
                                            alt={item.name}
                                        />
                                    }
                                </Grid>
                                <Grid item xs={6} md={5} lg={6} sx={{ alignSelf: 'center' }}>
                                    <Box sx={{ ml: 2 }}>
                                        <Typography variant="h6" gutterBottom>{item.name}</Typography>
                                        <Typography variant="body2" gutterBottom>{item.description}</Typography>
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            <div>
                                                {item.menuItemRestrictions.filter(x => x.section == 'Dietary').map(x =>
                                                {
                                                    return (
                                                        <div className="d-flex" style={{ alignItems: 'center' }}>
                                                            <img height={20} width={20} src={x.imageURI} />
                                                            <div className="ml-1">{x.name}</div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            {
                                                item.menuItemRestrictions.filter(x => x.section == 'Allergen').length > 0 &&
                                                <div style={{ marginLeft: '40px' }}>
                                                    <b>{this.labels.HubLabelContains}:</b> {item.menuItemRestrictions.filter(x => x.section == 'Allergen').map(x => x.name).toString()}
                                                </div>
                                            }
                                        </div>
                                    </Box>
                                </Grid>
                                <Grid item xs={1} sx={{ alignSelf: 'center' }}><Typography sx={{ mb: 0 }} variant="body2" gutterBottom>{this.state.currency}{item.unitPrice.toFixed(2)}</Typography></Grid>
                                <Grid item xs={3} sx={{ alignSelf: 'center', textAlign: 'center', fontSize: '23px' }}>
                                    <IbssIconButton
                                        className="mr-1"
                                        aria-label="close"
                                        onClick={() => this.itemQuantityChanged(item.menuItemId, 'remove')}
                                    >
                                        <RemoveCircleOutlineIcon />
                                    </IbssIconButton>
                                    {item.quantity}
                                    <IbssIconButton
                                        className="ml-1"
                                        aria-label="close"
                                        onClick={() => this.itemQuantityChanged(item.menuItemId, 'add')}
                                    >
                                        <AddCircleOutlineIcon />
                                    </IbssIconButton>
                                </Grid>
                            </Grid>
                        ),
                        details: (
                            <div>
                                {this.labels.funcCateringOrderNutritionalInformation_S}:
                                <div>{item.nutritionalInformation}</div>
                            </div>
                        ),
                        hideExpand: item.nutritionalInformation == ''
                    }
                )
            })
        )
    }

    private getNoItemsMessage(classification: string): ReactElement
    {
        const itemsAvailableWithoutFilter = this.state.orderItems?.filter(x => x.classification == classification && x.availableToOrder).length == 0;

        let classificationLabel = '';

        switch (classification)
        {
            case 'MenuItemClassificationBeverage':
                classificationLabel = this.labels.funcCateringOrderBeverages_S.toLocaleLowerCase();
                break;
            case 'MenuItemClassificationSnack':
                classificationLabel = this.labels.funcCateringOrderSnacks_S.toLocaleLowerCase();
                break;
            case 'MenuItemClassificationFood':
                classificationLabel = this.labels.funcCateringOrderFoods_S.toLocaleLowerCase()
                break;
            default:
                break;
        }

        return (
            <div>
                {
                    itemsAvailableWithoutFilter ?
                        <Card sx={{ height: '100%', marginTop: '10px' }}>
                            <div style={{ position: 'relative', top: '15%', textAlign: 'center' }}>
                                <img height={300} width={300} src={`/images/NoCateringMenuAvailable.svg`} alt="" />
                                <Typography className="mb-1" variant="h5">{this.labels.funcCateringOrderNoItems_L.replace('{classification}', classificationLabel)}</Typography>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '80px' }}>
                                    <div style={{ width: '350px' }}>{this.labels.funcCateringOrderNoItems_Message.replace('{classification}', classificationLabel)}</div>
                                </div>
                            </div>
                        </Card>
                        :
                        <Card sx={{ height: '100%', marginTop: '10px' }}>
                            <div style={{ position: 'relative', top: '15%', textAlign: 'center' }}>
                                <img height={300} width={300} src={`/images/NoCateringMenuAvailable.svg`} alt="" />
                                <Typography className="mb-1" variant="h5">{this.labels.funcCateringOrderNoItemsWithFilter_L.replace('{classification}', classificationLabel)}</Typography>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '80px' }}>
                                    <div style={{ width: '350px' }}>{this.labels.funcCateringOrderNoItemsWithFilter_Message.replace('{classification}', classificationLabel)}</div>
                                </div>
                            </div>
                        </Card>
                }
            </div>
        )
    }

    private onViewBookingClicked(): void
    {
        if (this.props.location.state?.originRoute)
        {
            this.props.history.push(this.props.location.state?.originRoute);
        }
        else
        {
            this.props.history.push(`/flex-my-bookings/${this.buildingId}/mybooking/${this.props.match.params.bookingid}/${this.props.match.params.spaceId}`);
        }
    };

    private onReturnHomeClicked(): void
    {
        this.props.history.push("/flex-home");
    };

    private orderSelected(orderId: string, skipUnsavedChangesCheck: boolean): void
    {
        if (this.state.unsavedChanges == true && skipUnsavedChangesCheck == false)
        {
            this.setState({ showUnsavedChangesPopup: true, attemptedOrderIdToSelect: orderId });
            return;
        }

        if (orderId == this.state.selectedOrderId)
        {
            return;
        }

        this.setState({ unsavedChanges: false, showUnsavedChangesPopup: false });
        const order = this.state.allCateringOrdersForBooking.filter(x => x.orderId == orderId)[0];
        if (order.menuItems.length > 0 && order.menuItems[0].menuId)
        {
            const cateringMenu = this.state.availableCateringMenus.filter(menu => menu.Menu_Id == order.menuItems[0].menuId)[0];
            const menuView = MenuView.fromApiModel(cateringMenu, order, this.isOneLens, this.state.bookingData, this.state.orderPolicies);
            this.setState({
                menu: menuView.menu,
                orderItems: menuView.orderItems,
                selectedRestrictions: order.cateringOrderRestrictions,
            });
        }
        else
        {
            this.setState({
                menu: {
                    menuId: this.state.availableCateringMenus.length > 0 ? this.state.availableCateringMenus[0].Menu_Id : '',
                    name: this.state.availableCateringMenus.length > 0 ? this.state.availableCateringMenus[0].Name : ''
                },
                orderItems: this.state.availableCateringMenus.length > 0 ? MenuView.fromApiModel(this.state.availableCateringMenus[0], order, this.isOneLens, this.state.bookingData, this.state.orderPolicies).orderItems : []
            });
        }

        this.setState({ order: order, originalOrder: order, selectedOrderId: orderId, selectedAllergenRestrictionsFilter: [], selectedDietaryRestrictionsFilter: [] });
    }

    private async addNewOrderSelected(skipUnsavedChangesCheck: boolean): Promise<void>
    {
        if (this.state.unsavedChanges == true && skipUnsavedChangesCheck == false)
        {
            this.setState({ showUnsavedChangesPopup: true, attemptedOrderIdToSelect: '' });
            return;
        }

        const cateringMenu = this.state.availableCateringMenus[0];
        const menuView = MenuView.fromApiModel(cateringMenu, null, this.isOneLens, this.state.bookingData, this.state.orderPolicies);
        this.setState({
            menu: menuView.menu,
            order: new OrderView({
                ...this.state.order,
                cateringServiceTime: DateHelper.fromIsoByNode(this.state.bookingData.Booking_Start, this.state.bookingData.Node_Id),
                cateringClearingTime: DateHelper.fromIsoByNode(this.state.bookingData.Booking_End, this.state.bookingData.Node_Id),
                cateringNotes: '',
                orderId: ''
            }),
            selectedRestrictions: [],
            orderItems: menuView.orderItems,
            selectedOrderId: '',
            showUnsavedChangesPopup: false,
            unsavedChanges: false
        });
    }

    private getItemPrice(itemId: string, menuId: string): number
    {
        if (this.state.availableCateringMenus.length == 0)
        {
            return 0;
        }
        const price = this.state.availableCateringMenus.filter(x => x.Menu_Id == menuId)[0]?.Menu_MenuItems.filter(x => x.MenuItem_Id == itemId)[0].MenuItem.UnitPrice;
        return price;
    }

    private getOrderCostTotal(items: MenuItemView[]): number
    {
        const orderTotal = items?.map(x => this.getItemPrice(x.id, x.menuId) * x.quantityOfItems).reduce((x, y) => { return x + y }, 0);
        return orderTotal;
    }

    private onAddFurtherOrdersClicked(): void
    {
        this.setState({ showCateringAddedPopup: false });
        this.addNewOrderSelected(false);
    }

    private getAllOrdersCostTotals(): number
    {
        let orderTotal = 0;

        this.state.allCateringOrdersForBooking.forEach(order =>
        {
            orderTotal = orderTotal + this.getOrderCostTotal(order.menuItems);
        });

        return orderTotal;
    }

    private getAllOrdersItemTotals(): number
    {
        let itemTotal = 0

        this.state.allCateringOrdersForBooking.forEach(order =>
        {
            if (order.menuItems.length > 0)
            {
                itemTotal = itemTotal + order.menuItems?.map(x => x.quantityOfItems).reduce((x, y) => { return x + y })
            }
        });

        return itemTotal;
    }

    public render(): JSX.Element
    {
        const { match } = this.props;
        const bookingStart = DateHelper.fromIsoByNode(this.state.bookingData.Booking_Start, this.state.bookingData.Node_Id);
        const bookingEnd = DateHelper.fromIsoByNode(this.state.bookingData.Booking_End, this.state.bookingData.Node_Id);
        const cutOffTimeDays = this.state.preBookCateringTime.split('.')[0];
        const cutOffTimeOfDay = this.state.preBookCateringTime.split('.')[1]?.split(':');
        const cutOffTime = cutOffTimeOfDay && bookingStart.date().plus({ days: -cutOffTimeDays }).set({ hour: parseInt(cutOffTimeOfDay[0]), minute: parseInt(cutOffTimeOfDay[1]) });
        const cutOffDuration = cutOffTime && cutOffTime.diff(DateTime.now().offsetTimeByNode(this.buildingId), ['days', 'hours', 'minutes']).toObject();

        const gridTaskDetailValues: IGridTaskDetailValues[] =
            [
                {
                    details: this.labels.HubLabelLocation,
                    original: this.state.originalOrder ? this.state.originalOrder.spaceId : '',
                    change: this.state.order ? this.state.order.spaceId : ''
                },
                {
                    details: this.labels.HubLabelServiceTime,
                    original: this.state.originalOrder ? `${this.state.originalOrder.cateringServiceTime.toFormat('HH:mm')}` : '',
                    change: this.state.order ? `${this.state.order.cateringServiceTime.toFormat('HH:mm')}` : '',
                },
                {
                    details: this.labels.HubLabelCleaningTime,
                    original: this.state.originalOrder ? `${this.state.originalOrder.cateringClearingTime.toFormat('HH:mm')}` : '',
                    change: this.state.order ? `${this.state.order.cateringClearingTime.toFormat('HH:mm')}` : '',
                },
                {
                    details: this.labels.HubLabelAttendees,
                    original: this.state.originalOrder ? this.state.originalOrder.cateringAttendees?.toString() : '',
                    change: this.state.order ? this.state.order.cateringAttendees?.toString() : ''
                },
                {
                    details: this.labels.HubLabelCodes,
                    original: this.state.originalOrder ? this.state.originalOrder.costCodeAllocation?.map((x: ICostAllocation) => x.costCode).toString().replaceAll(',', ', ') : '',
                    change: this.state.order ? this.state.costCodes?.map((x: ICostAllocation) => x.costCode).toString().replaceAll(',', ', ') : '',
                },
            ];

        const newOrder = this.state.order.orderId == "";

        const tabs: TabsParameters[] = [
            {
                label: this.labels.funcCateringOrderBeverages_S,
                components: (
                    this.getAccordionItems('MenuItemClassificationBeverage').length > 0 ?
                        <Box className="menu-items-scroll">
                            <IbssAccordion onlyExpandOnIconClick={true} values={this.getAccordionItems('MenuItemClassificationBeverage')} spacing="8px" />
                        </Box>
                        :
                        this.getNoItemsMessage('MenuItemClassificationBeverage')
                )
            },
            {
                label: this.labels.funcCateringOrderSnacks_S,
                components: (
                    this.getAccordionItems('MenuItemClassificationSnack').length > 0 ?
                        <Box className="menu-items-scroll">
                            <IbssAccordion onlyExpandOnIconClick={true} values={this.getAccordionItems('MenuItemClassificationSnack')} spacing="8px" />
                        </Box>
                        :
                        this.getNoItemsMessage('MenuItemClassificationSnack')
                )
            },
            {
                label: this.labels.funcCateringOrderFoods_S,
                components: (
                    this.getAccordionItems('MenuItemClassificationFood').length > 0 ?
                        <Box className="menu-items-scroll">
                            <IbssAccordion onlyExpandOnIconClick={true} values={this.getAccordionItems('MenuItemClassificationFood')} spacing="8px" />
                        </Box>
                        :
                        this.getNoItemsMessage('MenuItemClassificationFood')
                )
            },
        ];

        const allergenRestrictions = this.state.cateringRestrictions.filter(x => x.section == 'Allergen');
        const dietaryRestrictions = this.state.cateringRestrictions.filter(x => x.section == 'Dietary');
        const showStatusUpdateButtons = this.isOneLens;
        const pastCutOffPeriod = cutOffDuration ? (cutOffDuration.days ?? 1) < 1 && (cutOffDuration.hours ?? 1) < 1 && (cutOffDuration.minutes ?? 1) < 1 : false;

        return (
            <>
                {this.state.loading && <LoadingOverlay />}
                <div className="page-height-exct-header">
                    <UnsavedChangesPopup
                        open={this.state.showUnsavedChangesPopup}
                        onClose={() => this.setState({ showUnsavedChangesPopup: false })}
                        onCancelClicked={() => this.setState({ showUnsavedChangesPopup: false })}
                        onContinueClicked={() => this.state.attemptedOrderIdToSelect == '' ? this.addNewOrderSelected(true) : this.orderSelected(this.state.attemptedOrderIdToSelect, true)}
                    />
                    <Card style={{ padding: '30px', margin: '25px' }}>
                        <Typography variant="h6" gutterBottom>{this.labels.funcCatering_S}</Typography>
                        <div className="d-flex mb-3" style={{ justifyContent: 'space-between' }} >
                            <div className="d-flex">
                                <BookingDetailsWidget
                                    spaceName={this.state.bookingData.Space_Name}
                                    spaceImageUrl={this.state.spaces.filter(x => x.Space_Id == this.state.bookingData.Space_Id)[0]?.ImageURI ?? ""}
                                    start={bookingStart}
                                    end={bookingEnd}
                                    host={this.state.bookingData.Booking_Host_Name}
                                    owner={this.state.bookingData.Booking_Owner_Name}
                                />
                                {
                                    this.localStorage.hasRight("API.Catering.AssignCostCode") &&
                                    <div onClick={() => this.setState({ showCostCodeModal: true })} style={{ marginLeft: '90px', cursor: 'pointer', alignSelf: 'center', display: 'flex', justifyContent: 'space-between', width: '400px' }}>
                                        <div className="d-flex">
                                            <IbssSvgIcon fontSize='medium' className="" sx={{ color: (theme) => theme.palette.text.primary }}>
                                                {Icons.CostCodeIcon}
                                            </IbssSvgIcon>
                                            <div>{this.labels.HubLabelCostCodes}</div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="mr-1">{this.state.costCodes.length} {this.labels.HubLabelItems}</div>
                                            <IbssSvgIcon fontSize='medium' sx={{ color: (theme) => theme.palette.text.primary }}>
                                                {Icons.RightArrowIcon}
                                            </IbssSvgIcon>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="d-flex">
                                <div style={{ alignContent: 'end', marginRight: '15px' }}>{this.labels.funcEditCateringOrderGrandTotal_S}:</div>
                                <div style={{ alignContent: 'end', marginBottom: '-6px', textAlign: 'right' }}>
                                    <div>{this.getAllOrdersItemTotals()} {this.labels.HubLabelItems}</div>
                                    <Typography variant="h4">{this.state.currency} {this.getAllOrdersCostTotals()}</Typography>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap">
                            {
                                this.state.allCateringOrdersForBooking.map((order, index) =>
                                (
                                    <div key={order.orderId} className="mr-3 mb-1" style={{ height: '135px' }} onClick={() => this.orderSelected(order.orderId, false)}>
                                        <OrderSummaryWidget
                                            orderName={`${this.labels.funcEditCateringOrder_S} ` + (index + 1)}
                                            status={order.cateringStatus}
                                            start={order.cateringServiceTime}
                                            end={order.cateringClearingTime}
                                            itemTotal={order.menuItems.length == 0 ? 0 : order.menuItems?.map(x => x.quantityOfItems).reduce((x, y) => { return x + y })}
                                            costTotal={this.getOrderCostTotal(order.menuItems)}
                                            hasUnsavedChanges={this.state.unsavedChanges && this.state.selectedOrderId == order.orderId}
                                            selected={this.state.selectedOrderId == order.orderId}
                                            currency={this.state.currency}
                                        />
                                    </div>
                                )
                                )
                            }
                            {
                                !newOrder && this.hasMultipleOrdersRights &&
                                <div style={{ height: '135px', width: '385px' }}>
                                    <AddNewOrderWidget onClick={() => this.addNewOrderSelected(false)} />
                                </div>
                            }
                        </div>
                    </Card>
                    {
                        showStatusUpdateButtons &&
                        <div className="mt-1" style={{ marginLeft: '25px' }}>
                            {
                                this.state.order.cateringStatus == 'PendingApproval' &&
                                <IbssButton
                                    style={{ height: '45px', minWidth: '100px', marginRight: '30px' }}
                                    color='error'
                                    variant="contained"
                                    onClick={() => this.setState({ showStatusUpdateConfirmation: 'NotApproved' })}
                                >
                                    {this.labels.funcCateringOrderDecline_S}
                                </IbssButton>
                            }
                            {
                                this.state.order.cateringStatus == 'PendingApproval' &&
                                <IbssButton
                                    style={{ height: '45px', minWidth: '100px', marginRight: '30px' }}
                                    variant="contained"
                                    onClick={() => this.setState({ showStatusUpdateConfirmation: 'RequestApproved' })}
                                >
                                    {this.labels.HubLabelApprove}
                                </IbssButton>
                            }
                            {
                                this.state.order.cateringStatus == 'RequestApproved' && !pastCutOffPeriod &&
                                <IbssButton
                                    style={{ height: '45px', minWidth: '100px', marginRight: '30px' }}
                                    variant="contained"
                                    onClick={() => this.setState({ showStatusUpdateConfirmation: 'ReadyForPrep' })}
                                >
                                    {this.labels.funcCateringOrderReadyForPrep_S}
                                </IbssButton>
                            }
                            {
                                this.state.order.cateringStatus == 'ReadyForPrep' &&
                                <IbssButton
                                    style={{ height: '45px', minWidth: '100px', marginRight: '30px' }}
                                    variant="contained"
                                    onClick={() => this.setState({ showStatusUpdateConfirmation: 'PrepareForService' })}
                                >
                                    {this.labels.funcCateringOrderPrepareForService_S}
                                </IbssButton>
                            }
                            {
                                this.state.order.cateringStatus == 'PrepareForService' &&
                                <IbssButton
                                    style={{ height: '45px', minWidth: '100px', marginRight: '30px' }}
                                    variant="contained"
                                    onClick={() => this.setState({ showStatusUpdateConfirmation: 'ReadyForDespatchInTime' })}
                                >
                                    {this.labels.funcCateringOrderReadyInTime_S}
                                </IbssButton>
                            }
                            {
                                this.state.order.cateringStatus == 'PrepareForService' &&
                                <IbssButton
                                    style={{ height: '45px', minWidth: '100px', marginRight: '30px' }}
                                    variant="contained"
                                    onClick={() => this.setState({ showStatusUpdateConfirmation: 'ReadyForDespatchOutOfTime' })}
                                >
                                    {this.labels.funcCateringOrderReadyLate_S}
                                </IbssButton>
                            }
                            {
                                (this.state.order.cateringStatus == 'ReadyForDespatchInTime' || this.state.order.cateringStatus == 'ReadyForDespatchOutOfTime') &&
                                <IbssButton
                                    style={{ height: '45px', minWidth: '100px', marginRight: '30px' }}
                                    variant="contained"
                                    onClick={() => this.setState({ showStatusUpdateConfirmation: 'Delivered' })}
                                >
                                    {this.labels.funcCateringOrderDelivered_S}
                                </IbssButton>
                            }
                            {
                                this.state.order.cateringStatus == 'Delivered' &&
                                <IbssButton
                                    style={{ height: '45px', minWidth: '100px', marginRight: '30px' }}
                                    variant="contained"
                                    onClick={() => this.setState({ showStatusUpdateConfirmation: 'DeliveryIssue' })}
                                >
                                    {this.labels.funcCateringOrderDeliveryIssue_S}
                                </IbssButton>
                            }
                            {
                                this.state.order.cateringStatus == 'Delivered' &&
                                <IbssButton
                                    style={{ height: '45px', minWidth: '100px', marginRight: '30px' }}
                                    variant="contained"
                                    onClick={() => this.setState({ showStatusUpdateConfirmation: 'ClearUpScheduled' })}
                                >
                                    {this.labels.funcCateringOrderClearedUpInTime_S}
                                </IbssButton>
                            }
                            {
                                this.state.order.cateringStatus == 'Delivered' &&
                                <IbssButton
                                    style={{ height: '45px', minWidth: '100px', marginRight: '30px' }}
                                    variant="contained"
                                    onClick={() => this.setState({ showStatusUpdateConfirmation: 'ClearUpExcess' })}
                                >
                                    {this.labels.funcCateringOrderClearedUpLate_S}
                                </IbssButton>
                            }
                            {
                                (this.state.order.cateringStatus == 'ClearUpExcess' || this.state.order.cateringStatus == 'ClearUpScheduled') &&
                                <IbssButton
                                    style={{ height: '45px', minWidth: '100px', marginRight: '30px' }}
                                    variant="contained"
                                    onClick={() => this.setState({ showStatusUpdateConfirmation: 'Cleared' })}
                                >
                                    {this.labels.funcCateringOrderCleared_S}
                                </IbssButton>
                            }
                            {
                                (this.state.order.cateringStatus == 'RequestApproved' ||
                                    this.state.order.cateringStatus == 'ReadyForPrep' ||
                                    this.state.order.cateringStatus == 'PrepareForService' ||
                                    this.state.order.cateringStatus == 'ReadyForDespatchInTime' ||
                                    this.state.order.cateringStatus == 'DeliveryIssue' ||
                                    this.state.order.cateringStatus == 'ReadyForDespatchOutOfTime') &&
                                <IbssButton
                                    style={{ height: '45px', minWidth: '100px', marginRight: '30px' }}
                                    variant="contained"
                                    color="error"
                                    onClick={() => this.setState({ showStatusUpdateConfirmation: 'CancelledCharged' })}
                                >
                                    {this.labels.funcCateringOrderCancelOrderCharge_S}
                                </IbssButton>
                            }
                            {
                                (this.state.order.cateringStatus == 'RequestApproved' ||
                                    this.state.order.cateringStatus == 'ReadyForPrep' ||
                                    this.state.order.cateringStatus == 'PrepareForService' ||
                                    this.state.order.cateringStatus == 'ReadyForDespatchInTime' ||
                                    this.state.order.cateringStatus == 'DeliveryIssue' ||
                                    this.state.order.cateringStatus == 'ReadyForDespatchOutOfTime') &&
                                <IbssButton
                                    style={{ height: '45px', minWidth: '100px', marginRight: '30px' }}
                                    variant="contained"
                                    color="error"
                                    onClick={() => this.setState({ showStatusUpdateConfirmation: 'CancelledNoCharge' })}
                                >
                                    {this.labels.funcCateringOrderCancelOrderNoCharge_S}
                                </IbssButton>
                            }
                        </div>
                    }
                    <div className="rightPanel-main-content">
                        <Grid container spacing={2}>
                            <Grid item md={5} xl={4}>
                                <OrderSummaryPanel
                                    bookingName={this.state.order.bookingName}
                                    bookingStart={bookingStart}
                                    bookingEnd={bookingEnd}
                                    preBookCateringTime={this.state.preBookCateringTime}
                                    buildingId={this.buildingId}
                                    cateringServiceTime={this.state.order.cateringServiceTime}
                                    cateringClearingTime={this.state.order.cateringClearingTime}
                                    skeletonLoad={this.state.skeletonLoad}
                                    newOrder={newOrder}
                                    cateringStatus={this.state.order.cateringStatus}
                                    onCateringServiceTimeChange={(value) => this.handleChangeCateringServiceTime(value)}
                                    onCateringClearingTimeChange={(value) => this.handleChangeCateringClearingTime(value)}
                                    onNotesChange={(value) => this.setState({ order: new OrderView({ ...this.state.order, cateringNotes: value }), unsavedChanges: true })}
                                    isOneLens={this.isOneLens}
                                    cateringNotes={this.state.order.cateringNotes}
                                    orderItems={this.state.orderItems}
                                    currency={this.state.currency}
                                    displayRestrictionsModal={() => this.displayRestrictionsModal()}
                                    selectedRestrictions={this.state.selectedRestrictions}
                                    cateringRestrictions={this.state.cateringRestrictions}
                                    createClicked={() => this.create()}
                                    cancelClicked={() => this.cancelChanges()}
                                    updateClicked={() => this.setState({ showConfirmationModal: true })}
                                    availableMenus={this.state.availableCateringMenusView.map(x => ({ label: x.menu.name, value: x.menu.menuId }))}
                                    menuChanged={(menuId) => this.menuChanged(menuId)}
                                    selectedMenu={this.state.menu.menuId}
                                />
                            </Grid>
                            <Grid item md={7} xl={8}>
                                <Card sx={{ padding: 1 }} className="mb-3">
                                    <IbssButton variant="contained" onClick={() => this.displayFilterModal()} >{this.labels.HubLabelFilter}</IbssButton>
                                </Card>
                                {
                                    this.state.skeletonLoad ?
                                        <div>
                                            <Card sx={{ height: '124px', marginBottom: '10px', marginTop: '10px' }}><MenuItemSkeleton /></Card>
                                            <Card sx={{ height: '124px' }}><MenuItemSkeleton /></Card>
                                        </div> :
                                        this.state.menu.menuId == '' ?
                                            <Card sx={{ height: '100%' }}>
                                                <div style={{ position: 'relative', top: '15%', textAlign: 'center' }}>
                                                    <img height={300} width={300} src={`/images/NoCateringMenuAvailable.svg`} alt="" />
                                                    <Typography className="mb-1" variant="h5">{this.labels.funcCateringOrderNoMenuAvailable_S}</Typography>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <div style={{ width: '350px' }}>{this.labels.funcCateringOrderNoMenuAvailable_Message}</div>
                                                    </div>
                                                </div>
                                            </Card>
                                            :
                                            <IbssHorizontalTabs
                                                sx={{ backgroundColor: 'var(--ui-background-alternate)' }}
                                                tabs={tabs}
                                                orientation="horizontal"
                                                boxwidth='100%'
                                            />
                                }
                            </Grid>
                            <Modal show={this.state.showConfirmationModal} onHide={() => this.setState({ showConfirmationModal: false })}>
                                <Modal.Header>
                                    <Modal.Title>{this.labels.HubLabelTaskId}: {this.state.order?.orderId}</Modal.Title>
                                    <button type="button" className="close" onClick={() => this.setState({ showConfirmationModal: false })} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </Modal.Header>
                                <div className='m-4'>
                                    {this.labels.HubLabelEditCateringTaskModalSubtext}
                                </div>
                                {/* Task details */}
                                <Grid container item spacing={2} p={4}>
                                    <Grid item xs={4}>
                                        {this.labels.HubLabelDetailslabel}
                                    </Grid>
                                    <Grid item xs={4}>
                                        {this.labels.HubLabelOriginal}
                                    </Grid>
                                    <Grid item xs={4}>
                                        {this.labels.HublabelChange}
                                    </Grid>
                                    {gridTaskDetailValues.map(x =>
                                    {
                                        return (
                                            <Grid container key={x.details} item spacing={2}>
                                                <Grid item xs={4}>
                                                    {x.details}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {x.original}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {x.change}
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                    <Grid item xs={12}>
                                        <hr />
                                    </Grid>
                                    <Box>
                                        <Grid container spacing={2} pl={2}>
                                            <Grid item xs={3}>
                                                {this.labels.HubLabelSection}
                                            </Grid>
                                            <Grid item xs={5}>
                                                {this.labels.HubLabelItem}
                                            </Grid>
                                            <Grid item xs={2}>
                                                {this.labels.HubLabelQty}
                                            </Grid>
                                            <Grid item xs={2}>
                                                {this.labels.HubLabelCost}
                                            </Grid>

                                            {this.state.orderItems.map((x) =>
                                            {
                                                if (x.quantity > 0)
                                                {
                                                    return (
                                                        <Grid container item key={x.menuItemId} spacing={2} pl={2}>
                                                            <Grid item xs={3}>
                                                                <Typography variant="body1">
                                                                    {this.getClassificationLabel(x.classification)}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <Typography variant="body1">
                                                                    {x.name}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Typography variant="body1">
                                                                    {x.quantity}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Typography variant="body1">
                                                                    {this.state.currency}
                                                                    {(x.unitPrice * x.quantity).toFixed(2)}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }
                                            })}
                                        </Grid>
                                    </Box>
                                </Grid>
                                {/* Order details */}
                                <Modal.Footer>
                                    <div style={{ textAlign: 'center' }}>
                                        <IbssButton
                                            style={{ height: '45px', minWidth: '100px' }}
                                            color='error'
                                            variant="contained"
                                            onClick={() => this.updateOrder()}
                                        >
                                            {this.labels.HubButtonSave}
                                        </IbssButton>
                                    </div>
                                </Modal.Footer>
                            </Modal>
                            {/* -----------------------------Start Restrictions Popup---------------------------*/}
                            <IbssDialog
                                open={this.state.showRestrictionsModal}
                                onClose={() => this.closeRestrictionsModal()}
                                dialogContent=
                                {
                                    <>
                                        <div className="mb-3">{this.labels.funcCateringOrderAllergens_D}</div>
                                        <Divider />
                                        <div className="mb-2 mt-1">{this.labels.funcCateringOrderRemoveFoods_M}:</div>
                                        <div className="mb-3" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {allergenRestrictions.map(restriction => (<IbssCheckBox checked={this.state.selectedRestrictionsEdit.map(x => x.id).includes(restriction.id)} label={restriction.name} onClicked={(e: { target: { checked: boolean } }) => this.restrictionCheckboxSelected(e.target.checked, restriction)} />))}
                                        </div>
                                        <div style={{ textAlign: 'right' }}>
                                            <IbssButton variant="contained" onClick={() => this.applyRestrictionUpdated()}>{this.labels.HubButtonSave}</IbssButton>
                                        </div>
                                    </>
                                }
                                header={this.labels.funcCateringOrderAllergyInformation_S}
                                fullWidth
                            />
                            {/* -----------------------------End Restrictions Popup---------------------------*/}

                            {/* -----------------------------Start Filter Popup---------------------------*/}
                            <IbssDialog
                                open={this.state.showFilterModal}
                                onClose={() => this.closeFilterModal()}
                                dialogContent=
                                {
                                    <>
                                        <div className="mb-3">{this.labels.funcCateringOrderFilter_Message}</div>
                                        <Divider />
                                        <div className="mb-2 mt-1">{this.labels.funcCateringOrderRemoveFoods_M}:</div>
                                        <div className="mb-3" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {allergenRestrictions.map(restriction => (<IbssCheckBox checked={this.state.selectedAllergenRestrictionsFilterEdit.includes(restriction.name)} label={restriction.name} onClicked={(e: { target: { checked: boolean } }) => this.allergenFilterCheckboxSelected(e.target.checked, restriction.name)} />))}
                                        </div>
                                        <Divider />
                                        <div className="mb-2 mt-1">{this.labels.funcCateringOrderOnlyShowMarkedFoods_M}:</div>
                                        <div className="mb-3" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {dietaryRestrictions.map(restriction => (<IbssCheckBox checked={this.state.selectedDietaryRestrictionsFilterEdit.includes(restriction.name)} label={restriction.name} onClicked={(e: { target: { checked: boolean } }) => this.dietaryFilterCheckboxSelected(e.target.checked, restriction.name)} />))}
                                        </div>
                                        <div style={{ textAlign: 'right' }}>
                                            <IbssButton variant="contained" onClick={() => this.applyRestrictionFilterUpdated()}>{this.labels.funcCateringOrderApply_S}</IbssButton>
                                        </div>
                                    </>
                                }
                                header={this.labels.funcCateringOrderFilterBy_S}
                                fullWidth
                            />
                            {/* -----------------------------End Filter Popup---------------------------*/}

                            {/* -----------------------------Start Cost codes Popup---------------------------*/}
                            <CostCodesDialog
                                buildingId={this.buildingId}
                                selectedCostCodes={this.state.costCodes}
                                show={this.state.showCostCodeModal}
                                onClose={() => this.handleCostCodeModal()}
                                updateBookingCostCodes={(updatedCostCodes) => this.updateBookingCostCodes(updatedCostCodes)}
                            />
                            {/* -----------------------------End Cost codes Popup---------------------------*/}
                        </Grid>
                        <Modal show={this.state.showStatusUpdateConfirmation != ''} onHide={() => this.setState({ showStatusUpdateConfirmation: '' })}>
                            <Modal.Header>
                                <Modal.Title>{this.labels.HubLabelModalConfirmTitle}</Modal.Title>
                                <button type="button" className="close" onClick={() => this.setState({ showStatusUpdateConfirmation: '' })} aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </Modal.Header>
                            <div className='m-4'>
                                {this.labels.funcCateringOrderStausUpdate_Message}
                            </div>
                            <Modal.Footer>
                                <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
                                    <IbssButton
                                        style={{ height: '45px', minWidth: '100px' }}
                                        color='secondary'
                                        variant="contained"
                                        onClick={() => this.setState({ showStatusUpdateConfirmation: '' })}
                                    >
                                        {this.labels.HubButtonCancel}
                                    </IbssButton>
                                    <IbssButton
                                        style={{ height: '45px', minWidth: '100px' }}
                                        color='primary'
                                        variant="contained"
                                        onClick={() => this.updateOrderStatus(this.state.showStatusUpdateConfirmation)}
                                    >
                                        {this.labels.HubLabelOk}
                                    </IbssButton>
                                </div>
                            </Modal.Footer>
                        </Modal>
                        {/* CATERING ADDED MODAL */}
                        <IbssDialog
                            aria-modal="true"
                            aria-label="equipment modal"
                            open={this.state.showCateringAddedPopup}
                            fullWidth
                            dialogContent=
                            {
                                <div className='d-flex justify-content-between'>
                                    <Box sx={{ flex: 1 }}>
                                        <div className="d-flex justify-content-center">
                                            <div>
                                                <div style={{ textAlign: 'center' }}>
                                                    <IbssSvgIcon sx={{ marginLeft: '-22px' }} className="font-18">{Icons.Success}</IbssSvgIcon>
                                                </div>
                                                <p style={{ paddingTop: "5px" }}>{this.labels.funcCateringAdded_S}!</p>
                                            </div>
                                        </div>
                                    </Box>
                                </div>
                            }
                            footer=
                            {
                                <div className='d-flex w-90' style={{ justifyContent: 'space-between' }}>
                                    <IbssButton
                                        variant="contained"
                                        color='secondary'
                                        onClick={() => this.onViewBookingClicked()}
                                    >
                                        {this.labels.funcViewBooking_S}
                                    </IbssButton>
                                    <div>
                                        <IbssButton
                                            variant="contained"
                                            color='secondary'
                                            className="mr-2"
                                            onClick={() => this.onReturnHomeClicked()}
                                        >
                                            {this.labels.funcCreateBookingReturnHome_S}
                                        </IbssButton>
                                        <IbssButton
                                            variant="contained"
                                            onClick={() => this.onAddFurtherOrdersClicked()}
                                        >
                                            {this.labels.funcEditCateringOrderAdd_L}
                                        </IbssButton>
                                    </div>
                                </div>
                            }
                            onClose={() => this.onReturnHomeClicked()}
                        />
                    </div>
                </div>
            </>
        );
    }

}

export class MenuItemSkeleton extends Component
{
    public render(): JSX.Element
    {
        return (
            <div style={{ display: 'flex', padding: '10px' }}>
                <Skeleton variant="rectangular" width={100} height={100} />
                <div style={{ width: '100%' }}>
                    <Skeleton sx={{ margin: '15px', marginBottom: '10px' }} variant="rectangular" width={'70%'} height={10} />
                    <Skeleton sx={{ margin: '15px' }} variant="rectangular" width={'50%'} height={10} />
                </div>
            </div>
        )
    }
}

export interface IProps extends RouteComponentProps<IQueryParams, StaticContext, IRouteState>, IPropsFromState
{
}

export interface IQueryParams
{
    bookingid: string;
    buildingid: string;
    cateringOrderId: string;
    spaceId: string;
}

export interface IRouteState
{
    bookingId?: string;
    originRoute?: string;
}

export interface IState
{
    order: OrderView;
    originalOrder: OrderView;
    allCateringOrdersForBooking: OrderView[];
    selectedOrderId: string;
    orderItems: CateringMenuItemView[];
    loading: boolean;
    currency: string;
    preBookCateringTime: string;
    showConfirmationModal: boolean;
    floorId: number;
    numOfAttendees: number;
    spaces: Space[];
    showCostCodeModal: boolean;
    lightModeTheme: boolean;
    costCodes: CostCodeWithAllocation[];
    menu: ICateringMenuView;
    cateringRestrictions: ICateringRestrictionView[];
    selectedRestrictions: ICateringRestrictionView[];
    selectedRestrictionsEdit: ICateringRestrictionView[];
    showRestrictionsModal: boolean;
    selectedAllergenRestrictionsFilter: string[],
    selectedAllergenRestrictionsFilterEdit: string[],
    selectedDietaryRestrictionsFilter: string[],
    selectedDietaryRestrictionsFilterEdit: string[],
    showFilterModal: boolean,
    itemOrderPolicies: ICateringOrderPolicyView[],
    skeletonLoad: boolean,
    showStatusUpdateConfirmation: string,
    resetCateringStatus: boolean,
    availableCateringMenusView: MenuView[],
    showCateringAddedPopup: boolean,
    bookingData: IGetV2BookingResponse,
    orderPolicies: ICateringOrderPolicy[],
    availableCateringMenus: ICateringMenu[],
    unsavedChanges: boolean,
    showUnsavedChangesPopup: boolean,
    attemptedOrderIdToSelect: string
}

export class OrderView
{
    public orderId = "";
    public nodeId = 0;
    public menuItems = new Array<MenuItemView>();
    public cateringServiceTime = DateHelper.null();
    public cateringClearingTime = DateHelper.null();
    public cateringStatus = "";
    public cateringAttendees = "";
    public cateringNotes = "";
    public costCodeAllocation: ICostAllocation[] = [];
    public cateringOrderRestrictions = [];
    public bookingName = "";
    public bookingId = "";
    public spaceName = "";
    public spaceId = "";
    public menuId = "";

    constructor(value?: Partial<OrderView>)
    {
        if (value == null)
        {
            return;
        }
        Object.assign(this, value);
    }

    public static fromApiTask(data: ICateringOrder): OrderView
    {
        return new OrderView(
            {
                orderId: data.Order_Id,
                nodeId: data.Node_Id,
                menuItems: data.Menu_Items.map(i => MenuItemView.fromApiModel(i)),
                cateringServiceTime: DateHelper.fromIsoByNode(data.Catering_Service_Time, data.Node_Id),
                cateringClearingTime: DateHelper.fromIsoByNode(data.Catering_Clearing_Time, data.Node_Id),
                cateringStatus: data.Catering_Status,
                cateringAttendees: data.Catering_Attendees,
                cateringNotes: data.Catering_Notes,
                costCodeAllocation: JSON.parse(data.Cost_Code_Allocation).map((x: { Cost_Code: string; Cost_Code_Id: string; Allocation: number }) => ({ costCode: x.Cost_Code, costCodeId: x.Cost_Code_Id, allocation: x.Allocation })),
                cateringOrderRestrictions: data.Catering_Order_Restrictions.length > 0 ? JSON.parse(data.Catering_Order_Restrictions) : [],
                bookingName: data.Booking_Name,
                bookingId: data.Booking_Id,
                spaceName: data.Space_Name,
                spaceId: data.Space_Id,
                menuId: data.Menu_Id
            });
    }

    public toApiOrder(spaceId: string, bookingId: string, nodeId: number, numOfAttendees: number, orderItems: string, costCodeAllocations: ICostCodeAllocation[], orderTotal: number, restrictions: ICateringRestrictionView[], orderStatus: string, cateringServiceTime: string, cateringClearingTime: string): ICreateCateringOrder
    {
        const payload: ICreateCateringOrder =
        {
            Node_Id: nodeId,
            Menu_Items: orderItems,
            Booking_Id: bookingId,
            Space_Id: spaceId,
            Catering_Service_Time: cateringServiceTime,
            Catering_Clearing_Time: cateringClearingTime,
            Catering_Status: orderStatus,
            Catering_Attendees: numOfAttendees,
            Catering_Notes: this.cateringNotes,
            Cost_Code_Allocation: JSON.stringify(costCodeAllocations),
            Catering_Total_Value: orderTotal,
            Catering_Order_Restrictions: JSON.stringify(restrictions)
        };

        return payload;
    }
}

export class MenuItemView
{
    public id = "";
    public menuId = "";
    public quantityOfItems = 0;

    public static fromApiModel(data: IMenuItem): MenuItemView
    {
        return {
            id: data.Id,
            menuId: data.Menu_Id,
            quantityOfItems: data.QuantityOfItems,
        };
    }
}

export class MenuView
{
    public menu = {
        menuId: '',
        name: ''
    };
    public orderItems: CateringMenuItemView[] = [];

    public static itemAvailableToOrder(bookingData: IGetV2BookingResponse, menuItem: IMenuItems, orderPolicy: ICateringOrderPolicy[]): boolean
    {

        const itemPolicy = orderPolicy.filter(policy => policy.OrderPolicy_Id == menuItem.MenuItem.OrderPolicy_Id)[0];

        let availableToOrder = true;
        if (itemPolicy)
        {
            const prepCompletionTime = DateTime.now().plus({ minutes: itemPolicy.PreparationTime_Mins });
            const kitchenOpenWithinBookingTimes = parseInt(DateTime.fromISO(bookingData.Booking_Start).toFormat('HH:mm').replace(':', '')) >= parseInt(itemPolicy.Kitchen_OperatingHours_Start.replace(':', '')) && parseInt(DateTime.fromISO(bookingData.Booking_End).toFormat('HH:mm').replace(':', '')) <= parseInt(itemPolicy.Kitchen_OperatingHours_Stop.replace(':', ''));
            if (prepCompletionTime > DateTime.fromISO(bookingData.Booking_Start) || !kitchenOpenWithinBookingTimes)
            {
                availableToOrder = false;
            }
        }
        return availableToOrder;
    }

    public static fromApiModel(cateringMenu: ICateringMenu, order: OrderView | null, isOneLens: boolean, bookingData: IGetV2BookingResponse, orderPolicy: ICateringOrderPolicy[]): MenuView
    {
        return {
            menu: {
                menuId: cateringMenu.Menu_Id,
                name: cateringMenu.Name,
            },
            orderItems: cateringMenu.Menu_MenuItems.map(menuItem =>
            {
                const item = (order == null ? null : order.menuItems.filter(i => i.id == menuItem.MenuItem_Id)[0]);
                const availableToOrder = isOneLens ? true : this.itemAvailableToOrder(bookingData, menuItem, orderPolicy);

                return ({
                    description: menuItem.MenuItem.Description,
                    status: menuItem.MenuItem.Status,
                    unitPrice: menuItem.MenuItem.UnitPrice,
                    classification: menuItem.MenuItem.Classification,
                    retailPrice: menuItem.MenuItem.RetailPrice,
                    name: menuItem.MenuItem.Name,
                    supplierId: menuItem.MenuItem.Supplier_Id,
                    menuItemId: menuItem.MenuItem_Id,
                    nutritionalInformation: menuItem.MenuItem.NutritionalInformation,
                    orderPolicy_Id: menuItem.MenuItem.OrderPolicy_Id,
                    imageURI: menuItem.MenuItem.ImageURI,
                    quantity: item?.quantityOfItems ?? 0,
                    meetsFilterRequirements: true,
                    availableToOrder: availableToOrder,

                    menuItemRestrictions: menuItem.MenuItem.MenuItem_Restrictions.map(x => ({
                        id: x.Restriction_Id || '',
                        section: x.Restriction.Section,
                        imageURI: x.Restriction.ImageURI,
                        name: x.Restriction.Name
                    }))
                })
            }),
        };
    }
}

export interface ICostAllocation
{
    costCode: string;
    costCodeId: string;
    allocation: number;
    costCodeDescription: string;
}

class CostAllocationView
{
    public costCode: string;
    public costCodeId: string;
    public allocation: number;
    public costCodeDescription: string;

    public constructor()
    {
        this.costCode = '';
        this.costCodeId = '';
        this.allocation = 0;
        this.costCodeDescription = '';
    }

    public static fromUserPrefDefaultCateringCostCodes(cateringCostCode: IDefaultCostCodes): ICostAllocation
    {
        return {
            costCode: cateringCostCode.CostCodeName,
            costCodeId: cateringCostCode.CostCodeID,
            allocation: parseInt(cateringCostCode.CostCodeApportionment),
            costCodeDescription: cateringCostCode.CostCodeDescription,
        }
    }
}

export interface IGridTaskDetailValues
{
    details: string;
    original: string;
    change: string;
}

export interface CateringMenuItemView
{
    description: string;
    status: Status;
    unitPrice: number;
    classification: Classification;
    retailPrice: number;
    name: string;
    supplierId: string;
    menuItemId: string;
    nutritionalInformation: string;
    orderPolicy_Id: string;
    imageURI: string;
    quantity: number;
    availableToOrder: boolean;
    menuItemRestrictions: ICateringRestrictionView[];
    meetsFilterRequirements: boolean;
}

export interface ICateringRestrictionView
{
    id: string;
    section: string;
    imageURI: string;
    name: string;
}

export interface ICateringMenuView
{
    menuId: string;
    name: string;
}

export interface ICateringOrderPolicyView
{
    policyId: string;
    advancedNotice: string;
    preperationTimeMins: number;
    kitchenHoursStart: string;
    kitchenHoursStop: string;
}