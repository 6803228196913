import { ODataSelect } from "../../../../Providers.Api/ODataQuery";

export class CateringOrder extends ODataSelect
{
    Menu_Id = "";
    Order_Id = "";
    Node_Id = 0;
    Menu_Items = "";
    Catering_Service_Time = "";
    Catering_Clearing_Time = "";
    Catering_Status = "";
    Catering_Attendees = "";
    Catering_Notes = "";
    Cost_Code_Allocation = "";
    Catering_Order_Restrictions = "";
    Booking_Name = "";
    Booking_Id = "";
    Space_Name = "";
    Space_Id = "";
}
