import React, { Component } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Box, Typography, TextField, Divider } from '@mui/material';
import { appContext } from '../../../../AppContext';
import IbssFormControl from '../../../../Components/Forms/FormControl/IbssFormControl';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';
import IbssSwitchLabel from '../../../../Components/Inputs/Switch/IbssSwitchLabel';
import IbssTimePicker from '../../../../Components/Inputs/TimePicker/IbssTimePicker';
import IbssDatePicker from '../../../../Components/Inputs/DatePicker/IbssDatePicker';
import { DateTime, DurationObjectUnits, Duration } from 'luxon';
import EmployeeOrVisitorPicker, { IOnBehalfOf } from '../../../../Components/DialogLaunchers/EmployeeOrVisitorPicker/EmployeeOrVisitorPicker';
import BookingPartiesPicker, { IAttendee } from '../../../../Components/DialogLaunchers/BookingPartiesPicker/BookingPartiesPicker';
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as MapPinIcon } from '../../../../Components/Layout/Sidebar/icons/MapPin.svg'
import { Icons } from '../../../../Common/AllsvgIcons';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { ISelectedSlot } from './ViewBookingsSchedule';
import { CostCodeWithAllocation } from '../../../../Components/Dialogs/CostCodesDialog/CostCodesDialog';
import CostCodesPicker from '../../../../Components/DialogLaunchers/CostCodesPicker/CostCodesPicker';
import { ICreateV2BookingRequest } from '../../../../Providers.Api/Bookings/CreateV2BookingEndpoint';
import { IUpdateV2BookingRequest } from '../../../../Providers.Api/Bookings/UpdateV2BookingEndpoint';
import { ICreateBookingResponse } from '../../../../Providers.Api/Bookings/CreateV1BookingEndpoint';
import { IUpdateBookingResponse } from '../../../../Providers.Api/Bookings/UpdateV1BookingEndpoint';
import { DateHelper } from '../../../../Common/DateHelper';
import { IBookingSlots } from '../../../../Providers.Api/BookingPolicies/BookingPolicyRepository';
import ConfirmBookingDialog from '../../../../Components/Dialogs/ConfirmBookingDialog/ConfirmBookingDialog';
import { RouteComponentProps } from 'react-router';
import TimeZoneOffset from '../../../../Components/Miscellaneous/TimeZoneOffset/TimeZoneOffset';
import CancelBookingModal from './CancelBookingModal';
import { BookingView } from './ViewBookingsSchedule';
import { ISpaceArrangement } from '../../../../Components/Dialogs/SpaceArrangementsDialog/SpaceArrangementsDialog';
import SpaceArrangementPicker from '../../../../Components/DialogLaunchers/SpaceArrangementsPicker/SpaceArrangementPicker';
import { ISpaceLayout } from '../../../../Components/Dialogs/SpaceArrangementsDialog/SpaceArrangementsDialog';
import CateringOrderPicker from '../../../../Components/Navigation/CateringOrdersLink/CateringOrderPicker';
import EquipmentsPicker, { IBookingAndSpace } from '../../../../Components/DialogLaunchers/EquipmentPicker/EquipmentsPicker';
import SwapSpace from './SwapSpace';
import { BookingHelper } from '../../../../Common/BookingHelper';
import SwapDateTime from './SwapDatetime';
import { IPatchV2BookingRequest } from '../../../../Providers.Api/Bookings/PatchV2BookingEndpoint';
import { CateringOrdersFilter, CateringStatus } from '../../../../Providers.Api/CateringOrders/CateringOrderRepository';
import { BookingSlotHelper } from '../../../../Common/BookingSlotHelper';
import { ICateringOrders, IMenuItem } from '../../../../Providers.Api/CateringOrders/CateringOrderRepository';
import IbssToolTip from '../../../../Components/Miscellaneous/Tooltip/IbssToolTip';
import InfoIcon from '../../../../Components/Icons/InfoIcon';
import IbssDrawer from '../../../../Components/Drawer/IbssDrawer';
import ChangeBookingDetailsDialog, { IBookingDetailsApplication } from '../../../../Components/Dialogs/ChangeBookingDetailsDialog/ChangeBookingDetailsDialog';
import { IBookingResources, IGetV2BookingResponse } from '../../../../Providers.Api/Bookings/GetV2BookingEndpoint';
import { IEquipmentPostModel } from '../../../../Providers.IbssApiClientV1/IbssApiClientV1';

class BookingCriteria extends Component<IProps, IState>
{
    private get alert() { return appContext().alert; }
    private get api() { return appContext().apiClient; }
    private get appState() { return appContext().state; }
    private get bookingService() { return appContext().bookingService; }
    private currentDate: Date = DateHelper.fromIsoToJsDate(DateHelper.now().toString());
    private get labels() { return appContext().labels; }
    private get local() { return appContext().localStorageProvider; }
    private get session() { return appContext().sessionStorageProvider; }
    private spaceLayout: ISpaceLayout[];
    private displayName = '';
    private bookOnBehalfOFRef: React.RefObject<EmployeeOrVisitorPicker>
    private deleteBookingService = new DeleteBookingService();
    private canReadCateringOrders = this.local.hasRight("DATAMODEL.CateringOrders.Read");
    private get hasV2Rights() { return appContext().localStorageProvider.hasRight("API.Bookings.V2"); }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            name: '',
            description: '',
            bookingDate: new Date(),
            start: new Date(),
            end: new Date(),
            lightModeTheme: this.appState.lightModeTheme,


            autoCheckin: false,
            onlineMeetingLink: false,
            onBehalfOf: '',
            onBehalfOfData: { email: "", firstName: "", lastName: "", company: "", isVisitor: false },
            bookingOwnerName: '',
            bookingParties: [],
            bookingPartyVisitors: [],
            costCodes: [],
            useOnBehalfOfCostCodes: 0,

            isStartValid: true,
            isEndValid: true,
            timeDifference: '',

            bookingSlotsPolicy: {} as IBookingSlots,
            startIntervalMinutes: [],
            endIntervalMinutes: [],

            bookingResponse: {} as ICreateBookingResponse,
            showCateringAidsModal: false,
            showCancelBookingModal: false,

            selectedSeatingArrangement: { breakdown: 0, capacity: 0, setup: 0, style: '' },
            selectedLayoutSpaceId: '',
            selectedLayoutName: '',
            selectedLayoutStyle: '', // e.g. boardRoom,
            cateringOrder: {} as CateringOrderView,
            cateringItemsTotal: 0,

            isSwapSpace: false,
            isSwapDateTime: false,
            bookingAttendance: 0,
            hearingAidTotal: 0,
            presentationAidTotal: 0,
            bookingEquipment: [],
        }

        this.spaceLayout = [];
        this.bookOnBehalfOFRef = React.createRef();
    }

    public componentWillMount(): void
    {
        this.appState.autoMap<IState>(this, i => ({ lightModeTheme: i.lightModeTheme }));
    }

    public componentDidMount(): void
    {
        // set default start and end time for time pickers, in case user's selectedSlot somehow returns null. 
        const searchCriteria = this.session.getFlexSpaceSearchCriteria();
        const startTime = searchCriteria.startTime.offsetTimeByNode(this.props.buildingId);
        const endTime = searchCriteria.endTime.offsetTimeByNode(this.props.buildingId);

        this.displayName = this.local.getUserDetails().displayName;
        this.setState({
            start: startTime.toJSDate(),
            end: endTime.toJSDate(),
            bookingDate: startTime.toJSDate(),
            timeDifference: (endTime.diff(startTime, ["hours", "minutes"])).durationToString(),
        });
    }

    public async componentDidUpdate(prevProps: IProps): Promise<void>
    {
        if (this.props.selectedSpace[0]?.spaceId !== prevProps.selectedSpace[0]?.spaceId)
        {
            // get space's booking policy and set bookingSlotsPolicy, startIntervalMinutes & endIntervalMinutes
            const policyId = this.props.selectedSpace[0]?.bookingPolicyId;
            if (policyId && policyId !== '0')
            {
                const bookingPolicy = await this.api.bookingPolicies.getBookingPolicy(this.props.buildingId, policyId);
                if (bookingPolicy == null)
                {
                    return;
                }

                this.setState({
                    startIntervalMinutes: JSON.parse(bookingPolicy.Booking_Policy).BookingSlots.BookingStart.SpecificMinutes,
                    endIntervalMinutes: JSON.parse(bookingPolicy.Booking_Policy).BookingSlots.BookingEnd.SpecificMinutes,
                    bookingSlotsPolicy: JSON.parse(bookingPolicy.Booking_Policy).BookingSlots ?? {}
                });
            }
            // parse the json from space.spaceLayout 
            this.spaceLayout = this.props.selectedSpace[0]?.spaceLayout ? JSON.parse(this.props.selectedSpace[0]?.spaceLayout).Layouts.map((x: { Name: string; Space_Id: string; SeatingArrangements: { Breakdown: number; Capacity: number; Setup: number; Style: string; }[]; }) =>
            {
                return (
                    {
                        name: x.Name,
                        spaceId: x.Space_Id,
                        seatingArrangements:
                            x.SeatingArrangements.map((arrangement: { Breakdown: number; Capacity: number; Setup: number; Style: string; }) => { return ({ breakdown: arrangement.Breakdown, capacity: arrangement.Capacity, setup: arrangement.Setup, style: arrangement.Style }) })
                    }
                )
            }) : [];

            // after setting this.spaceLayout, set default layout and linked space states
            if (this.props.selectedBooking)
            {
                const selectedSpaceLayout = this.spaceLayout.filter(i => i.spaceId === this.props.selectedBooking?.spaceId)[0]
                this.setState(
                    {
                        selectedLayoutSpaceId: this.props.selectedBooking.spaceId,
                        selectedLayoutName: selectedSpaceLayout?.name ?? '', //spaceId and layout name is one to one e.g. "1CC_03-NE-R005;1CC_03-SE-R006" to R005/R006 
                        selectedLayoutStyle: this.props.selectedBooking.spaceLayout,
                        selectedSeatingArrangement: selectedSpaceLayout?.seatingArrangements.find(j => j.style === this.props.selectedBooking?.spaceLayout) ?? { breakdown: 0, capacity: 0, setup: 0, style: '' }
                    }
                );
            }
            else
            {
                this.setState(
                    {
                        selectedLayoutSpaceId: this.spaceLayout[0]?.spaceId ?? '',
                        selectedLayoutName: this.spaceLayout[0]?.name ?? '',
                        selectedLayoutStyle: this.spaceLayout[0]?.seatingArrangements[0].style ?? '',
                        selectedSeatingArrangement: this.spaceLayout[0]?.seatingArrangements[0] ?? { breakdown: 0, capacity: 0, setup: 0, style: '' },
                    }
                );
            }
        }

        // if user has selected a free time slot on the calendar, record the start, end and bookingDate & calculate the timeDifference.
        if (this.props.selectedSlot && (this.props.selectedSlot?.spaceId !== prevProps.selectedSlot?.spaceId || this.props.selectedSlot?.startTime !== prevProps.selectedSlot?.startTime || this.props.selectedSlot?.endTime !== prevProps.selectedSlot?.endTime))
        {
            const startTime = this.props.selectedSlot.startTime;
            const endTime = this.props.selectedSlot.endTime;
            this.setState(
                {
                    start: startTime,
                    end: endTime,
                    bookingDate: startTime,
                    timeDifference: (DateTime.fromJSDate(endTime).diff(DateTime.fromJSDate(startTime), ["hours", "minutes"])).durationToString(),
                }
            );
        }

        // if selected booking's booking id has changed and it exists
        if (this.props.selectedBooking?.bookingId !== prevProps.selectedBooking?.bookingId)
        {
            if (this.props.selectedSpace[0]?.spaceId)
            {
                // parse the json from space.spaceLayout 
                this.spaceLayout = this.props.selectedSpace[0]?.spaceLayout ? JSON.parse(this.props.selectedSpace[0]?.spaceLayout).Layouts.map((x: { Name: string; Space_Id: string; SeatingArrangements: { Breakdown: number; Capacity: number; Setup: number; Style: string; }[]; }) =>
                {
                    return (
                        {
                            name: x.Name,
                            spaceId: x.Space_Id,
                            seatingArrangements:
                                x.SeatingArrangements.map((arrangement: { Breakdown: number; Capacity: number; Setup: number; Style: string; }) => { return ({ breakdown: arrangement.Breakdown, capacity: arrangement.Capacity, setup: arrangement.Setup, style: arrangement.Style }) })
                        }
                    )
                }) : [];
            }

            if (this.props.selectedBooking?.bookingId)
            {
                const { bookingId, nodeId } = this.props.selectedBooking;

                // if bookingId, make a call to get booking info
                const bookingData = await this.bookingService.get(nodeId, bookingId);
                bookingData.Booking_Start = DateTime.fromISO(bookingData.Booking_Start).offsetTimeByNode(nodeId).toISO();
                bookingData.Booking_End = DateTime.fromISO(bookingData.Booking_End).offsetTimeByNode(nodeId).toISO();

                // get additional cost codes data.
                let costCodesWithAllocation: Array<CostCodeWithAllocation> = [];
                const bookingCostCodes = bookingData.Cost_Code_Allocation;
                if (bookingCostCodes)
                {
                    const costCodeIds = bookingCostCodes.map(i => i.Cost_Code_Id);
                    const costCodePromises = costCodeIds.map(id => this.api.costCodes.getCostCodeById(id));
                    const costCodes = await Promise.all(costCodePromises);
                    costCodesWithAllocation = costCodes.map(i =>
                    {
                        return new CostCodeWithAllocation(i, bookingCostCodes.filter(j => j.Cost_Code_Id === i.Cost_Code_Id)[0].Allocation);
                    });
                }

                this.setState({
                    start: DateTime.fromISO(bookingData.Booking_Start).toJSDate(),
                    end: DateTime.fromISO(bookingData.Booking_End).toJSDate(),
                    name: bookingData.Booking_Name,
                    description: bookingData.Booking_Description,
                    bookingAttendance: bookingData.Booking_Attendance,
                    onBehalfOf: bookingData.Booking_Owner_Email,
                    onBehalfOfData: {
                        // todo- Flex's EditBooking's booking onBehalfOf function, on which this is modelled, has a bug, and is incomplete, booking api GET call does not return booking Owner's company or isVisitor,
                        email: bookingData.Booking_Owner_Email,
                        firstName: bookingData.Booking_Owner_Name,
                        lastName: '',
                        company: '',
                        isVisitor: false
                    },
                    bookingParties: bookingData.Booking_Parties.filter(i => i.Booking_Participant_Type === 1).map(i => 
                    {
                        const participant =
                        {
                            ...i,
                            Booking_Resource_Id: i?.Booking_Resource_Id ?? '',
                        }
                        return new IAttendee(participant);
                    }
                    ),
                    bookingPartyVisitors: bookingData.Booking_Parties.filter(i => i.Booking_Participant_Type === 2).map(i => 
                    {
                        const participant =
                        {
                            ...i,
                            Booking_Resource_Id: i?.Booking_Resource_Id ?? '',
                        }
                        return new IAttendee(participant);
                    }
                    ),
                    bookingOwnerName: bookingData.Booking_Owner_Name,
                    costCodes: costCodesWithAllocation,
                    selectedLayoutSpaceId: bookingData.Space_Id,
                    selectedLayoutName: this.spaceLayout.filter(i => i.spaceId === bookingData.Space_Id)[0]?.name ?? '',
                    selectedLayoutStyle: bookingData.Space_Layout,
                    selectedSeatingArrangement: { breakdown: 0, capacity: 0, setup: 0, style: '' },
                    onlineMeetingLink: JSON.parse(bookingData.Online_Meeting).JoinUrl.length > 0,
                    bookingEquipment: bookingData.Booking_Resources,
                });

                // get catering details if there are any.
                await this.getCatering();
                await this.getEquipmentTotal();
            }
            else
            {
                // if clicked on a cell instead of on a booking, reset states.
                this.resetStates();
            }
        }

        // this.props.selectedBooking === undefined and selectedSlot changes, user has clicked on a different slot so reset BookingCriteria states. 
        // wipe state if user clicks on a slot after clicking "I'll do it later" button on equipment and catering modal.
        if (this.props.selectedBooking === undefined && (prevProps.selectedSlot?.startTime !== this.props.selectedSlot?.startTime || prevProps.selectedSlot?.spaceId !== this.props.selectedSlot?.spaceId))
        {
            this.resetStates();
        }
    }

    private resetStates(): void
    {
        this.setState(
            {
                name: '',
                description: '',
                autoCheckin: false,
                onlineMeetingLink: false,
                onBehalfOf: '',
                onBehalfOfData: {
                    email: '',
                    firstName: '',
                    lastName: '',
                    company: '',
                    isVisitor: false
                },
                bookingOwnerName: '',
                bookingParties: [],
                bookingPartyVisitors: [],
                bookingResponse: null,
                costCodes: [],
                selectedLayoutSpaceId: this.spaceLayout[0]?.spaceId ?? '',
                selectedLayoutName: this.spaceLayout[0]?.name ?? '',
                selectedLayoutStyle: this.spaceLayout[0]?.seatingArrangements[0].style ?? '',
                selectedSeatingArrangement: this.spaceLayout[0]?.seatingArrangements[0] ?? { breakdown: 0, capacity: 0, setup: 0, style: '' },
                cateringOrder: {} as CateringOrderView,
                cateringItemsTotal: 0,
                bookingAttendance: 0,
            }
        );
    }

    private startTimeChanged(time: Dayjs | null): void
    {
        const date = DateTime.fromJSDate(this.state.bookingDate).date();
        const startTime = ((time == null || !time.isValid()) ? DateHelper.null() : DateTime.fromISO(time.toISOString()).set({ year: date.year, month: date.month, day: date.day }));
        let endTime = (!this.state.isEndValid ? DateHelper.null() : DateTime.fromJSDate(this.state.end).set({ year: date.year, month: date.month, day: date.day }));

        if (startTime.isValid && endTime.isValid && startTime > endTime)
        {
            endTime = startTime.clone();
        }

        const duration = (startTime.isValid && endTime.isValid ? endTime.diff(startTime, ["hours", "minutes"]) : null);
        const durationAsString = (duration == null ? "-" : duration.durationToString());

        this.setState(
            {
                start: (startTime.isValid ? startTime.toJSDate() : this.state.start),
                end: (endTime.isValid ? endTime.toJSDate() : this.state.end),
                isStartValid: startTime.isValid,
                isEndValid: endTime.isValid,
                timeDifference: durationAsString,
            });
    }

    private endTimeChanged(time: Dayjs | null): void
    {
        const date = DateTime.fromJSDate(this.state.bookingDate).date();
        let startTime = (!this.state.isStartValid ? DateHelper.null() : DateTime.fromJSDate(this.state.start).set({ year: date.year, month: date.month, day: date.day }));
        const endTime = ((time == null || !time.isValid()) ? DateHelper.null() : DateTime.fromISO(time.toISOString()).set({ year: date.year, month: date.month, day: date.day }));

        if (startTime.isValid && endTime.isValid && startTime > endTime)
        {
            startTime = endTime.clone();
        }

        const duration = (startTime.isValid && endTime.isValid ? endTime.diff(startTime, ["hours", "minutes"]) : null);
        const durationAsString = (duration == null ? "-" : duration.durationToString());

        this.setState(
            {
                start: (startTime.isValid ? startTime.toJSDate() : this.state.start),
                end: (endTime.isValid ? endTime.toJSDate() : this.state.end),
                isStartValid: startTime.isValid,
                isEndValid: endTime.isValid,
                timeDifference: durationAsString,
            });
    }

    private handleDateChange(event: Date): void
    {

        const selectedDate = new Date(event);

        const userPref = this.local.getUserPreferences();
        const datetime = DateTime.fromJSDate(selectedDate);
        const isToday = datetime.hasSame(DateHelper.now(), 'day');

        let startTime = this.currentDate;

        const myStartDateTime = startTime.toISOString();
        const myStartDate = myStartDateTime.split('T')[0];

        const userStartTime = userPref?.WorkingHoursPrefs?.UserStartTime;

        if (!isToday)
        {
            startTime = DateHelper.fromIsoToJsDate(myStartDate + 'T' + userStartTime);
        }

        this.setState({ bookingDate: selectedDate });
    }

    private async createBooking(): Promise<void>
    {
        // creates a booking and then closes the drawer.
        const nodeId = this.props.selectedSpace[0]?.floorId;
        if (nodeId)
        {
            const selectedDate = DateTime.fromJSDate(this.state.bookingDate)

            const startTime = selectedDate.set({ hour: this.state.start.getHours(), minute: this.state.start.getMinutes() });
            const endTime = selectedDate.set({ hour: this.state.end.getHours(), minute: this.state.end.getMinutes() });

            await this.create(nodeId, startTime, endTime);
        }
        this.props.setOpen(false);

        // refresh bookings
        await this.props.getBookings();

        // unselect currently selected slot so ScheduleView can show the booking
        this.props.unsetSelectedCell();
    }

    private async updateBooking(spaceId?: string, start?: Date, end?: Date): Promise<void>
    {
        const nodeId = this.props.selectedSpace[0]?.floorId;
        const bookingId = this.props.selectedBooking?.bookingId
        if (nodeId && bookingId && start && end)
        {
            const startTime = DateTime.fromJSDate(start);
            const endTime = DateTime.fromJSDate(end);
            await this.update(nodeId, bookingId, startTime, endTime, spaceId);
        }
        else if (nodeId && bookingId)
        {
            const startTime = DateTime.fromJSDate(this.state.start);
            const endTime = DateTime.fromJSDate(this.state.end);
            await this.update(nodeId, bookingId, startTime, endTime, spaceId);
        }
    }

    private async patchBooking(spaceId: string, startTime: DateTime, endTime: DateTime): Promise<void>
    {
        const nodeId = this.props.selectedSpace[0]?.floorId;
        const bookingId = this.props.selectedBooking?.bookingId
        if (nodeId && bookingId)
        {
            await this.patch(nodeId, bookingId, spaceId, startTime, endTime);
        }
    }

    private async create(nodeId: number, start: DateTime, end: DateTime): Promise<void>
    {
        const costCodePayload = this.state.costCodes.map(costCode =>
        ({
            Cost_Code: costCode.costCode,
            Cost_Code_Id: costCode.costCodeId,
            Allocation: costCode.allocation,
        }))

        const payload: ICreateV2BookingRequest =
        {
            _CreatedAt: '',
            _CreatedBy: this.local.getUserDetails().email,
            SpaceId: this.props.selectedSlot?.spaceId ?? '',
            Booking_Start: start.setZoneByNode(this.props.buildingId).toISO(),
            Booking_End: end.setZoneByNode(this.props.buildingId).toISO(),
            Booking_Name: this.state.name === '' ? 'Booking' : this.state.name,
            Booking_Description: this.state.description,
            BookingAutoCheckin: this.state.autoCheckin ? 1 : 0,
            DisableExtUpdate: false,
            Booking_Parties: this.state.bookingParties.concat(this.state.bookingPartyVisitors).map(x =>
            ({
                Booking_Participant_Email: x.email,
                Booking_Participant_Name: x.name,
                Booking_Participant_Organisation: x.organisation,
                Booking_Participant_Type: x.type,
                Booking_Visitor: x.visitor,
                Booking_Resource_Id: '',
            })),
            Cost_Code_Allocation: this.state.useOnBehalfOfCostCodes === 1 ? [] : costCodePayload,
            UseOnBehalfOfCostCodes: this.state.useOnBehalfOfCostCodes,
            AddOnlineMeetingLink: this.state.onlineMeetingLink ? 1 : 0,
            Booking_Attendance: isNaN(this.bookingAttendance()) ? 0 : this.bookingAttendance(),
        };

        if (this.state.selectedLayoutSpaceId)
        {
            payload.Space_Layout = this.state.selectedSeatingArrangement.style;
            payload.SpaceId = this.state.selectedLayoutSpaceId;
        }

        if (this.state.onBehalfOf)
        {
            const onBehalfOfPayload =
            {
                Email: this.state.onBehalfOfData.email,
                First_Name: this.state.onBehalfOfData.firstName,
                Last_Name: this.state.onBehalfOfData.lastName,
                Display_Name: null,
                Company: this.state.onBehalfOfData.company,
                Visit_Id: null,
                IsVisitor: this.state.onBehalfOfData.isVisitor ? true : false
            }
            payload.OnBehalfOf = JSON.stringify(onBehalfOfPayload);
            if (this.state.onBehalfOfData.isVisitor)
            {
                payload.Booking_Parties.push(
                    {
                        Booking_Participant_Email: this.state.onBehalfOfData.email,
                        Booking_Participant_Name: [this.state.onBehalfOfData.firstName, this.state.onBehalfOfData.lastName].join(" "),
                        Booking_Participant_Organisation: this.state.onBehalfOfData.company,
                        Booking_Participant_Type: 2,
                        Booking_Visitor: this.state.onBehalfOfData.isVisitor,
                        Booking_Resource_Id: '',
                    });
            }
            else
            {
                // addition to booking parties data if booked on behalf of someone who isn't a visitor.
                payload.Booking_Parties.push(
                    {
                        Booking_Participant_Email: this.state.onBehalfOfData.email,
                        Booking_Participant_Name: [this.state.onBehalfOfData.firstName, this.state.onBehalfOfData.lastName].join(" "),
                        Booking_Participant_Organisation: "",
                        Booking_Participant_Type: 4,
                        Booking_Visitor: this.state.onBehalfOfData.isVisitor,
                        Booking_Resource_Id: '',
                    });
            }
        }

        try
        {
            //this.setState({ isLoadingToSave: true, isLoading: true });
            const response = await this.bookingService.create(nodeId, payload);

            if (this.props.selectedSpace[0]?.hearingAidReqs == 1 || this.props.selectedSpace[0]?.presentationAidReqs == 1 || this.props.selectedSpace[0]?.cateringReqs == 1)
            {
                this.setState({ showCateringAidsModal: true });
            }
            // else
            // {
            //     this.redirectPath();
            // }
            this.setState({ bookingResponse: response });
        }
        catch (error)
        {
            console.log(error);
        }
        // finally
        // {
        //     this.setState({ isLoadingToSave: false, isLoading: false });
        // }
    }

    private async patch(nodeId: number, bookingId: string, spaceId: string, startDate: DateTime, endDate: DateTime): Promise<void>
    {
        const payload: IPatchV2BookingRequest =
        {
            SpaceId: spaceId,
            StartTime: startDate.setZoneByNode(this.props.buildingId), // patch Request converts it to utc timezone and then an ISO string.
            EndTime: endDate.setZoneByNode(this.props.buildingId),
        }

        try
        {
            await this.api.bookings.patchV2Booking(nodeId, bookingId, payload);
            this.setState({ bookingResponse: null });
        }
        catch (error)
        {
            console.log(error);
        }
    }

    private async update(nodeId: number, bookingId: string, start: DateTime, end: DateTime, spaceId?: string): Promise<void>
    {
        const costCodePayload = this.state.costCodes.map(costCode =>
        ({
            Cost_Code: costCode.costCode,
            Cost_Code_Id: costCode.costCodeId,
            Allocation: costCode.allocation,
        }))

        const payload: IUpdateV2BookingRequest =
        {
            SpaceId: spaceId || (this.props.selectedSlot?.spaceId ?? ''),
            Booking_Start: start.setZoneByNode(this.props.buildingId).toUTC().toISO(),
            Booking_End: end.setZoneByNode(this.props.buildingId).toUTC().toISO(),
            PartyCount: 0,
            Booking_Name: this.state.name === '' ? 'Booking' : this.state.name,
            Booking_Description: this.state.description,
            Booking_IsPrivate: 0,
            Organisation: "",
            Booking_Parties: this.state.bookingParties.concat(this.state.bookingPartyVisitors).map(x =>
            ({
                Booking_Participant_Email: x.email,
                Booking_Participant_Name: x.name,
                Booking_Participant_Organisation: x.organisation,
                Booking_Participant_Type: x.type,
                Booking_Visitor: x.visitor,
                Booking_Resource_Id: ''
            })),
            UseOnBehalfOfCostCodes: this.state.useOnBehalfOfCostCodes,
            Cost_Code_Allocation: this.state.useOnBehalfOfCostCodes === 1 ? [] : costCodePayload,
            DisableExtUpdate: false,
            AddOnlineMeetingLink: this.state.onlineMeetingLink ? 1 : 0,
            Booking_Attendance: isNaN(this.bookingAttendance()) ? 0 : this.bookingAttendance(),
        };

        // todo - clarify with Noel how can user swap a linked space?
        // if(this.state.selectedLayoutSpaceId && spaceId), can't carry selectedSeatingArrangement & selectedLayoutSpaceId into the swapped room! similar rooms are not necesarrily linked.
        if (this.state.selectedLayoutSpaceId && spaceId === undefined)
        {
            payload.Space_Layout = this.state.selectedSeatingArrangement.style;
            payload.SpaceId = this.state.selectedLayoutSpaceId;
        }

        if (this.state.onBehalfOf && this.local.hasRight("API.Bookings.BookOnBehalfOf"))
        {
            payload.OnBehalfOf = this.state.onBehalfOf;
        }

        try
        {
            // this.setState({ isLoadingToSave: true, isLoading: true });
            await this.bookingService.update(nodeId, bookingId, payload);
            this.setState({ bookingResponse: null });
        }
        catch (error)
        {
            console.log(error);
        }
        // finally
        // {
        //     this.setState({ isLoadingToSave: false, isLoading: false });
        // }
    }

    private bookingAttendance(): number 
    {
        const totalAttendees = this.state.bookingParties.length + this.state.bookingPartyVisitors.length;
        const bookingAttendance = (totalAttendees > 0 && this.state.bookingAttendance === 0) ? totalAttendees : this.state.bookingAttendance;
        return bookingAttendance;
    }

    private async getCatering(): Promise<void>
    {
        if (!this.canReadCateringOrders || this.props.selectedBooking === undefined || this.props.selectedSpace[0]?.cateringReqs !== 1)
        {
            return;
        }

        try
        {
            const filter = new CateringOrdersFilter({
                bookingId: this.props.selectedBooking?.bookingId
            });

            const orders = await this.api.cateringOrders.getMany(this.props.selectedBooking.nodeId, filter);
            if (orders.length > 0)
            {
                let itemTotal = 0

                orders.forEach(order =>
                {
                    if (order.Menu_Items.length > 0)
                    {
                        itemTotal = itemTotal + order.Menu_Items?.map(x => x.QuantityOfItems).reduce((x, y) => { return x + y })
                    }
                });

                this.setState({ cateringItemsTotal: itemTotal, cateringOrder: new CateringOrderView(orders[0]) });
            } else 
            {
                this.setState({ cateringItemsTotal: 0 });
            }
        }
        catch (error)
        {
            return;
        }
    }

    private closeDrawer(): void
    {
        this.setState({ isSwapSpace: false, isSwapDateTime: false });
        this.props.setOpen(false);
    }

    private redirectClicked(type: "Home" | "AnotherBooking")
    {
        const { history } = this.props;
        if (type === "Home")
        {
            history.push("/operational-services-home")
        }
        if (type === "AnotherBooking")
        {
            history.push(`/operational-services/buildings/${this.props.buildingId}/spaces/search`)
        }
    }

    private async handleRaiseTask(): Promise<void>
    {
        const props = this.props;
        props.history.push(`/operational-services-tasks/${props.buildingId}/task/0/1?spaceid=${props.selectedSpace[0]?.spaceId ?? ''}`);
    }

    private async getEquipmentTotal(): Promise<void>
    { 
        let i = 0;
        let hearingAidTotal = 0;
        let presetationAidTotal = 0;
        while (i < this.state.bookingEquipment.length)
        {
            let details = null;
            if (this.state.bookingEquipment[i].Booking_Participant_Type == 3)
            {
                details = await appContext().ibssApiClientV1.v1.byNodeid.equipment.byEquipid.get<IEquipmentPostModel>({
                    nodeId: 1,
                    equipid: this.state.bookingEquipment[i].Booking_Resource_Id,
                    schema: false
                })
            }
            if (details && details.Equip_Type == 'HearingAid')
            {
                hearingAidTotal = hearingAidTotal + 1;
            }
            if (details && details.Equip_Type == 'PresentationAid')
            {
                presetationAidTotal = presetationAidTotal + 1;
            }
            i += 1;
        }
        this.setState({ hearingAidTotal: hearingAidTotal, presentationAidTotal: presetationAidTotal })
    }

    private async getAllBookingResources(bookingId: string): Promise<void>
    {
        if(this.props.selectedBooking){
        try
        {
            const bookingResources = await appContext().ibssApiClientV2.v2.byNodeid.bookings.byBookingid.get<IGetV2BookingResponse>({
                nodeId: this.props.selectedSpace[0]?.floorId,
                bookingid: bookingId
            });
            this.setState({ bookingEquipment: bookingResources.Booking_Resources });
            this.getEquipmentTotal();
        }
        catch (error)
        {
        }
    }
    }

    private async getBookingResources(): Promise<void>
    {
        if(this.props.selectedBooking)
        {
            await this.getAllBookingResources(this.props.selectedBooking.bookingId);
            await this.getEquipmentTotal();
        }
    }

    private async cancelBooking(): Promise<void>
    {
        const nodeId = this.props.selectedSpace[0].floorId;
        const bookingId = this.props.selectedBooking?.bookingId;
        
        if(nodeId && bookingId)
        {
            try
            {
                const response = await this.deleteBookingService.execute(nodeId, bookingId, this.hasV2Rights);

                if(response)
                {
                    this.alert.show(this.labels.HubLabelSuccess, response.Message);
                }
            }
            finally
            {
                // refresh bookings, close BookingCriteria after a cancel booking try.
                await this.props.getBookings();
                this.setState({ showCancelBookingModal: !this.state.showCancelBookingModal });
                this.props.setOpen(false);
            }
        }
    }

    public render(): JSX.Element
    {
        const buttonMargin = '8px 0';
        return (
            <>
                    <IbssDrawer open={this.props.open} onClose={() => this.closeDrawer()} anchor='right' style={{ top: "60px", minWidth: "500px", maxWidth: "600", width: "600px", overflow: "auto", backgroundColor: "var(--ui-background-alternate)" }}>
                    {<Box className="flexMySearch-filter-criteria m-3 ml-5" display={!this.state.isSwapSpace && !this.state.isSwapDateTime ? 'block' : 'none'}>
                        <div className="flexMySearch-filter-criteria-header">
                            <span className="flexMySearch-filter-criteria-close" onClick={() => this.props.setOpen(false)}>&times;</span>
                        </div>
                        {/* standard header */}
                        {this.props.selectedBooking === undefined && <>
                            <div className="pageTitle">{this.labels.funcScheduleViewCreateBooking_S}</div>
                            <Typography sx={{ fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme) => theme.palette.text.primary }}>
                                {this.labels.funcBookingScheduleSelectSpace_L}
                            </Typography>
                        </>
                        }

                        {/* edit booking scheduleView header */}
                        {this.props.selectedBooking && this.props.layoutStyle === "scheduleView" && <>
                            <div className="pageTitle">{this.state.name}</div>
                        </>
                        }

                        <div id="column1" aria-label="search criteria column">
                            {/* start of edit booking scheduleView layout */}
                            {this.props.selectedBooking && this.props.layoutStyle === "scheduleView" && <>
                                <IbssFormControl fullWidth className='mb-3'>
                                    <IbssTextField
                                        disabled={true} // Noel wants these fields locked when user clicks on an appointment in scheduleView
                                        value={this.state.name} // bookingName
                                        fullWidth
                                        variant='standard'
                                        onChange={e => this.setState({ name: e.target.value })}
                                        inputProps={{ style: { fontSize: '1.25rem' } }}
                                        sx={{ input: { color: (theme) => theme.palette.text.secondary } }}
                                        label=
                                        {
                                            <Typography sx={{ fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme) => theme.palette.text.primary }}>{this.labels.HubLabelBookingName}</Typography>
                                        }
                                    />
                                </IbssFormControl>
                                <IbssFormControl fullWidth className='mb-3'>
                                    <IbssTextField
                                        disabled={true} // Noel wants these fields locked when user clicks on an appointment in scheduleView
                                        value={this.state.description} // bookingDescription
                                        fullWidth
                                        variant='standard'
                                        onChange={e => this.setState({ description: e.target.value })}
                                        sx={{ input: { color: (theme) => theme.palette.text.secondary } }}
                                        multiline
                                        label=
                                        {
                                            <Typography sx={{ fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme) => theme.palette.text.primary }}>{this.labels.HubLabelBookingDescription}</Typography>
                                        }
                                    />
                                </IbssFormControl>
                                <IbssFormControl fullWidth className='mb-3'>
                                    <IbssTextField
                                        value={this.bookingAttendance()}
                                        fullWidth
                                        type='number'
                                        variant='standard'
                                        onChange={e => this.setState({ bookingAttendance: parseInt(e.target.value) })}
                                        inputProps={{ style: { fontSize: '1.25rem' }, min:0, pattern: '[0-9]*'}}
                                        sx={{ input: { color: (theme) => theme.palette.text.secondary } }}
                                        label=
                                        {
                                            <Box component="span" display="flex" alignItems="center">
                                            <Typography sx={{ fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme) => theme.palette.text.primary }}>{this.labels.funcBookingAttendance_S}</Typography>
                                            <IbssToolTip title={this.labels.funcBookingAttendanceToolTip_L} arrow>
                                                    <Box component="span" ml={2}>
                                                        <InfoIcon />
                                                    </Box>
                                                </IbssToolTip>
                                            </Box>
                                        }
                                    />
                                </IbssFormControl>
                                <Box sx={{ display: 'flex', flexDirection: 'column', mr: 2, mb: 2, gap: 1 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '13px' }}>
                                            <IbssSvgIcon fontSize='inherit' sx={{ mr: '19px', color: (theme) => theme.palette.text.secondary }}>
                                                {Icons.UserIcon}
                                            </IbssSvgIcon>
                                            <Typography sx={{ fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme) => theme.palette.text.primary }}>
                                                {`${this.labels.HubLabelOwner}: ${this.state.onBehalfOf || this.state.bookingOwnerName}`}
                                            </Typography>
                                        </Box>
                                        <IbssButton variant='contained' color='secondary' disabled={BookingHelper.isImmutable(this.props.selectedBooking.bookingStatus)} onClick={() => this.bookOnBehalfOFRef.current?.setState({ showModal: true })}>
                                            {this.labels.HubButtonEdit}
                                        </IbssButton>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '13px' }}>
                                            <SvgIcon component={MapPinIcon} inheritViewBox className="mr-2" sx={{ height: '16px', width: '16px', color: (theme) => theme.palette.text.secondary }} />
                                            <Typography sx={{ fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme) => theme.palette.text.primary }}>
                                                {`${this.props.selectedBooking.spaceName}`}
                                            </Typography>
                                        </Box>
                                        <IbssButton variant='contained' color='secondary' disabled={BookingHelper.isImmutable(this.props.selectedBooking.bookingStatus)} onClick={() => this.setState({ isSwapSpace: !this.state.isSwapSpace })}>{this.labels.HubButtonEdit}</IbssButton>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '16px' }}>
                                            <IbssSvgIcon fontSize='inherit' className="mr-2" sx={{ color: (theme) => theme.palette.text.secondary }}>
                                                {Icons.calenderIcon}
                                            </IbssSvgIcon>
                                            <Typography sx={{ fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme) => theme.palette.text.primary }}>
                                                {`${DateTime.fromJSDate(this.state.start).toLocaleDateString()}`}
                                            </Typography>
                                        </Box>
                                        <IbssButton variant='contained' color='secondary' disabled={BookingHelper.isImmutable(this.props.selectedBooking.bookingStatus)} onClick={() => this.setState({ isSwapDateTime: !this.state.isSwapDateTime })}>{this.labels.HubButtonEdit}</IbssButton>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '13px' }}>
                                            <div className="mr-2">
                                                <img style={{ height: '16px', width: '16px' }} src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Time (Fill).svg`} alt="Time" />
                                            </div>
                                            <Typography sx={{ fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme) => theme.palette.text.primary }}>
                                                {`${DateTime.fromJSDate(this.state.start).toLocaleTimeString()} - ${DateTime.fromJSDate(this.state.end).toLocaleTimeString()} (${this.state.timeDifference})`}
                                            </Typography>
                                        </Box>
                                        <IbssButton variant='contained' color='secondary' disabled={BookingHelper.isImmutable(this.props.selectedBooking.bookingStatus)} onClick={() => this.setState({ isSwapDateTime: !this.state.isSwapDateTime })}>{this.labels.HubButtonEdit}</IbssButton>
                                    </Box>
                                </Box>
                                <Divider sx={{ mb: 2 }} />
                            </>}
                            {/*'end of edit booking scheduleView layout'*/}

                            {/* start of standard style layout */}
                            {/* use in scheduleView if creating a booking, or in standard layout regardless of whether creating or editing booking. */}
                            {((this.props.selectedBooking === undefined && this.props.layoutStyle === "scheduleView") || this.props.layoutStyle === "standard") && <>

                                <IbssFormControl fullWidth className='mb-3'>
                                    <IbssTextField
                                        value={this.state.name} // bookingName
                                        fullWidth
                                        variant='standard'
                                        onChange={e => this.setState({ name: e.target.value })}
                                        label=
                                        {
                                            <Typography sx={{ fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme) => theme.palette.text.primary }}>{this.labels.HubLabelBookingName}</Typography>
                                        }
                                    />
                                </IbssFormControl>
                                <IbssFormControl fullWidth className='mb-3'>
                                    <IbssTextField
                                        value={this.state.description} // bookingDescription
                                        fullWidth
                                        variant='standard'
                                        onChange={e => this.setState({ description: e.target.value })}
                                        label=
                                        {
                                            <Typography sx={{ fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme) => theme.palette.text.primary }}>{this.labels.HubLabelBookingDescription}</Typography>
                                        }
                                    />
                                </IbssFormControl>
                                <IbssFormControl fullWidth className='mb-3'>
                                    <IbssTextField
                                        value={this.bookingAttendance()}
                                        fullWidth
                                        type='number'
                                        variant='standard'
                                        onChange={e => this.setState({ bookingAttendance: parseInt(e.target.value) })}
                                        inputProps={{ min: 0, pattern: '[0-9]*'}}
                                        sx={{ input: { color: (theme) => theme.palette.text.secondary } }}
                                        label=
                                        {
                                            <Box component="span" display="flex" alignItems="center">
                                            <Typography sx={{ fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme) => theme.palette.text.primary }}>{this.labels.funcBookingAttendance_S}</Typography>
                                            <IbssToolTip title={this.labels.funcBookingAttendanceToolTip_L} arrow>
                                                    <Box component="span" ml={2}>
                                                        <InfoIcon />
                                                    </Box>
                                                </IbssToolTip>
                                            </Box>
                                        }
                                    />
                                </IbssFormControl>
                                <Box component="div" sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <Box style={{ alignSelf: 'center' }}>
                                        <Typography sx={{ fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme) => theme.palette.text.primary }}>
                                            {this.labels.HubLabelFrom}
                                        </Typography>
                                        <IbssTimePicker
                                            shouldDisableTime={(time, type) => BookingSlotHelper.isTimeUnavailable(time.toJSDate(), type, 'start', this.state.bookingDate, this.state.start, this.state.end, this.state.bookingSlotsPolicy)}
                                            slotProps={{ textField: { error: false } }}
                                            // className="inputboxmodel-date"
                                            value={DateTime.fromJSDate(this.state.start)}
                                            onChange={time => this.startTimeChanged(dayjs(time?.toJSDate()) as (Dayjs | null))}
                                            ampm={false}
                                            minutesStep={BookingSlotHelper.getMinutesStepFromPolicy(this.state.startIntervalMinutes)}
                                            //disabled={updateBookingBtnDisable}
                                        />
                                    </Box>
                                    <Box
                                        className="icon-text-inline mt-3"
                                        style={{
                                            padding: "10px 15px",
                                        }}>
                                        <img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 239.svg`} alt="" />
                                    </Box>
                                    <Box style={{ alignSelf: 'center' }}>
                                        <Typography sx={{ fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme) => theme.palette.text.primary }}>
                                            {this.labels.HubLabelTo}
                                        </Typography>
                                        <IbssTimePicker
                                            shouldDisableTime={(time, type) => BookingSlotHelper.isTimeUnavailable(time.toJSDate(), type, 'end', this.state.bookingDate, this.state.start, this.state.end, this.state.bookingSlotsPolicy)}
                                            slotProps={{ textField: { error: false } }}
                                            //className="inputboxmodel-date"
                                            value={DateTime.fromJSDate(this.state.end)}
                                            onChange={time => this.endTimeChanged(dayjs(time?.toJSDate()) as (Dayjs | null))}
                                            ampm={false}
                                            minutesStep={BookingSlotHelper.getMinutesStepFromPolicy(this.state.endIntervalMinutes)}
                                            // disabled={updateBookingBtnDisable}
                                        />
                                    </Box>
                                </Box>
                                <hr />
                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} sx={{ mr: 2, mb: 2 }}>
                                    <IbssDatePicker
                                        value={DateTime.fromJSDate(this.state.bookingDate)}
                                        onChange={(event) => this.handleDateChange(event?.toJSDate() as Date)}
                                        slotProps={{ inputAdornment: { sx: { padding: '0px', marginLeft: '0px' } } }}
                                        //disabled={updateBookingBtnDisable}
                                        minDate={DateHelper.now()}
                                        shouldDisableDate={date => BookingSlotHelper.isDateUnavailable(date as unknown as DateTime, this.state.bookingSlotsPolicy)}
                                    />
                                    <div className="icon-text-inline">
                                        <span className="space-icon-item"><img style={{ height: '18px', width: '18px', paddingRight: '5px' }} src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Time (Fill).svg`} alt="Time" /></span>
                                        <span className="timeDate space-text-item pb-4px">{this.state.timeDifference}</span>
                                    </div>

                                    <TimeZoneOffset nodeId={this.props.buildingId} />
                                </Box>
                                <Divider sx={{ mb: 2 }} />
                                {/* end of standard style layout */}
                            </>
                            }
                            {
                                this.props.selectedSpace[0]?.meetingLinkAvailable == 1 &&
                                <>
                                    <Box>
                                        <IbssFormControl className='mb-3 mt-3'>
                                            <IbssSwitchLabel
                                                id="onlineMeeting"
                                                label={
                                                    <Typography sx={{ fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme) => theme.palette.text.primary }}>
                                                        {this.labels.HubLabelOnlineMeetingLink}
                                                    </Typography>
                                                }
                                                checked={this.state.onlineMeetingLink}
                                                onChange={e => this.setState({ onlineMeetingLink: e.target.checked })}
                                            />
                                        </IbssFormControl>
                                    </Box>
                                    <Divider sx={{ mb: 2 }} />
                                </>
                            }

                            {/* use in scheduleView if creating a booking, or in standard layout regardless of whether creating or editing booking. */}
                            {(this.local.hasRight("API.Bookings.BookOnBehalfOf") && (
                                <Box sx={{
                                    display: ((this.props.selectedBooking === undefined && this.props.layoutStyle === "scheduleView") || this.props.layoutStyle === "standard") ? 'block' : 'none'
                                }}>
                                    <EmployeeOrVisitorPicker
                                        ref={this.bookOnBehalfOFRef}
                                        onSubmit={(onBehalfOf: string, onBehalfOfData: IOnBehalfOf, autoCheckin: boolean, useOnBehalfOfCostCodes: number) => this.setState({ onBehalfOf: onBehalfOf, onBehalfOfData: onBehalfOfData, autoCheckin: autoCheckin, useOnBehalfOfCostCodes: useOnBehalfOfCostCodes })}
                                        onBehalfOfData={this.state.onBehalfOfData} 
                                        buildingId={this.props.buildingId}
                                        />
                                </Box>
                            ))}

                            {(parseInt(this.props.selectedSpace[0]?.capacity) > 1 &&
                                <Box>
                                    <BookingPartiesPicker
                                        onSubmit={(parties: IAttendee[], visitors: IAttendee[]) => this.setState({ bookingParties: parties, bookingPartyVisitors: visitors })}
                                        bookingParties={this.state.bookingParties}
                                        bookingPartyVisitors={this.state.bookingPartyVisitors}
                                    />
                                </Box>
                            )}
                            {(this.local.hasRight("API.Bookings.AssignCostCode") && this.local.hasRight("API.Bookings.V2")) && (
                                <Box>
                                    <CostCodesPicker onSubmit={(costCodes: CostCodeWithAllocation[]) => this.setState({ costCodes: costCodes })} selectedCostCodes={this.state.costCodes} />
                                </Box>
                            )}

                            <SpaceArrangementPicker
                                bookingStatus={this.props.selectedBooking?.bookingStatus ?? ''}
                                showSpaceLayout={this.props.selectedSpace[0]?.spaceSetup > 1}
                                showSpaceArrangement={this.props.selectedSpace[0]?.spaceSetup > 1}

                                spaceId={this.props.selectedBooking?.spaceId || this.props.selectedSpace[0]?.spaceId}
                                selectedLayoutName={this.state.selectedLayoutName}
                                selectedLayoutStyle={this.state.selectedLayoutStyle}

                                spaceSetup={this.props.selectedSpace[0]?.spaceSetup ?? 0}
                                spaceLayout={this.props.selectedSpace[0]?.spaceLayout ?? ''}
                                onSubmit={(seating: ISpaceArrangement, spaceId: string, spaceName: string) => this.setState(
                                    {
                                        selectedSeatingArrangement: seating,
                                        selectedLayoutSpaceId: spaceId,
                                        selectedLayoutName: spaceName,
                                        selectedLayoutStyle: seating.style,
                                    }
                                )}
                            />
                            {// hearing aids button where there isn't a bookingId
                                this.props.selectedSpace[0]?.hearingAidReqs === 1 && this.props.selectedBooking === undefined &&
                                <div>
                                    <div className="row">
                                        <div className="disabled-labels" style={{ justifyContent: 'unset', marginTop: '-1rem' }}>
                                            <IbssSvgIcon sx={{ marginTop: '3px', color: (theme) => theme.palette.text.secondary }} fontSize='medium' className="mr-2">
                                                {Icons.HearingAidIcon}
                                            </IbssSvgIcon>
                                            <div className="icon-text-inline">
                                                <span className="space-text-item mt-0 pt-0 ">{this.labels.HubLabelHearingConfirmation}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="mt-0" />
                                </div>
                            }
                            {// hearing aids button where there is a bookingId
                                this.props.selectedSpace[0]?.hearingAidReqs === 1 && this.props.selectedBooking?.bookingId &&
                                <>
                                    <EquipmentsPicker
                                        disabled={BookingHelper.isImmutable(this.props.selectedBooking.bookingStatus)}
                                        equipmentType="HearingAid"
                                        buildingId={this.props.buildingId}
                                        bookingId={this.props.selectedBooking?.bookingId ?? ""}
                                        bookingStart={this.props.selectedBooking?.bookingStart ?? ""}
                                        bookingEnd={this.props.selectedBooking?.bookingEnd ?? ""}
                                        bookingName={this.props.selectedBooking?.bookingName ?? ""}
                                        hearingAidReqs={this.props.selectedSpace[0]?.hearingAidReqs ?? 0}
                                        presentationAidReqs={this.props.selectedSpace[0]?.presentationAidReqs ?? 0}
                                        floorId={this.props.selectedSpace[0]?.floorId ?? 0}
                                        spaceId={this.props.selectedBooking.spaceId}
                                        spaceName={this.props.selectedBooking.spaceName}
                                        bookedEquipment={this.state.bookingEquipment}
                                        hearingAidTotal={this.state.hearingAidTotal}
                                        presentationAidTotal={this.state.presentationAidTotal}
                                        equipmentSubmitted={() => this.getBookingResources()}
                                    />
                                    <hr />
                                </>
                            }

                            {// presentation aids button where there isn't a bookingId
                                this.props.selectedSpace[0]?.presentationAidReqs === 1 && this.props.selectedBooking === undefined &&
                                <div>
                                    <div className="row">
                                        <div className="disabled-labels" style={{ justifyContent: 'unset', marginTop: '-1rem' }}>
                                            <IbssSvgIcon sx={{ marginTop: '3px', color: (theme) => theme.palette.text.secondary }} fontSize='medium' className="mr-2">
                                                {Icons.PresentationAidIcon}
                                            </IbssSvgIcon>
                                            <div className="icon-text-inline">
                                                <span className="space-text-item mt-0 pt-0 ">{this.labels.HubLabelAvailabelconfirmation}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="mt-0" />
                                </div>
                            }
                            {// presentation aids button where there is a bookingId
                                this.props.selectedSpace[0]?.presentationAidReqs === 1 && this.props.selectedBooking?.bookingId &&
                                <>
                                    <EquipmentsPicker
                                        disabled={BookingHelper.isImmutable(this.props.selectedBooking.bookingStatus)}
                                        equipmentType="PresentationAid"
                                        buildingId={this.props.buildingId}
                                        bookingId={this.props.selectedBooking?.bookingId ?? ""}
                                        bookingStart={this.props.selectedBooking?.bookingStart ?? ""}
                                        bookingEnd={this.props.selectedBooking?.bookingEnd ?? ""}
                                        bookingName={this.props.selectedBooking?.bookingName ?? ""}
                                        hearingAidReqs={this.props.selectedSpace[0]?.hearingAidReqs ?? 0}
                                        presentationAidReqs={this.props.selectedSpace[0]?.presentationAidReqs ?? 0}
                                        floorId={this.props.selectedSpace[0]?.floorId ?? 0}
                                        spaceId={this.props.selectedBooking.spaceId}
                                        spaceName={this.props.selectedBooking.spaceName}
                                        bookedEquipment={this.state.bookingEquipment}
                                        hearingAidTotal={this.state.hearingAidTotal}
                                        presentationAidTotal={this.state.presentationAidTotal}
                                        equipmentSubmitted={() => this.getBookingResources()}
                                    />
                                    <hr />
                                </>
                            }
                            {
                                this.canReadCateringOrders &&
                                <>
                                    {/* Catering button when there is no bookingId */}
                                    {this.props.selectedSpace[0]?.cateringReqs === 1 && this.props.selectedBooking === undefined && (
                                        <div>
                                            <div className="row">
                                                <div className="disabled-labels" style={{ justifyContent: 'unset', marginTop: '-1rem' }}>
                                                    <IbssSvgIcon sx={{ marginTop: '3px', color: (theme) => theme.palette.text.secondary }} fontSize='medium' className="mr-2">
                                                        {Icons.CateringIcon}
                                                    </IbssSvgIcon>
                                                    <div className="icon-text-inline">
                                                        <span className="space-text-item mt-0 pt-0 ">{this.labels.HubLabelAvailabelCatering}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className="m-0" />
                                        </div>
                                    )}
                                    {/* Catering button when there is bookingId */}
                                    {this.props.selectedSpace[0]?.cateringReqs === 1 && this.props.selectedBooking?.bookingId && (
                                        <CateringOrderPicker
                                            bookingId={this.props.selectedBooking?.bookingId ?? ''}
                                            buildingId={this.props.buildingId}
                                            cateringOrder={this.state.cateringOrder}
                                            cateringItemsTotal={this.state.cateringItemsTotal}
                                            spaceId={this.props.selectedBooking?.spaceId ?? ''}
                                            metaServReqsCatering={this.props.selectedSpace[0]?.cateringReqs}
                                        />
                                    )}
                                </>
                            }
                            <hr />

                            <IbssButton
                                variant="contained"
                                color="secondary"
                                fullWidth
                                size={'large'}
                                sx={{ margin: buttonMargin }}
                                onClick={async () => await this.handleRaiseTask()}
                            >
                                <Typography sx={{ fontFamily: 'Source Sans Pro', fontWeight: 'bold' }}>
                                    {this.labels.funcRaiseATask_S}
                                </Typography>
                            </IbssButton>

                            {!this.props.selectedBooking?.bookingId && (
                                <IbssButton
                                    variant="contained"
                                    fullWidth size={'large'}
                                    sx={{ margin: buttonMargin }}
                                    onClick={async () => this.createBooking()}
                                >
                                    <Typography sx={{ fontFamily: 'Source Sans Pro', fontWeight: 'bold' }}>
                                        {this.labels.HubButtonConfirmBooking}
                                    </Typography>
                                </IbssButton>
                            )}

                            {this.props.selectedBooking?.bookingId && (
                                <IbssButton
                                    variant="contained"
                                    fullWidth
                                    size={'large'}
                                    sx={{ margin: buttonMargin, backgroundColor: (theme) => theme.palette.error.main }}
                                    onClick={() => this.setState({ showCancelBookingModal: true })}
                                    disabled={BookingHelper.isImmutable(this.props.selectedBooking.bookingStatus)}
                                >
                                    <Typography sx={{ fontFamily: 'Source Sans Pro', fontWeight: 'bold' }}>
                                        {this.labels.HubButtonCancelBooking}
                                    </Typography>
                                </IbssButton>
                            )}

                            {this.props.selectedBooking?.bookingId && (
                                <IbssButton
                                    variant="contained"
                                    fullWidth
                                    size={'large'}
                                    sx={{ margin: buttonMargin }}
                                    onClick={async () =>
                                    {
                                        await this.updateBooking();
                                        this.props.setOpen(false);
                                        // refresh bookings
                                        await this.props.getBookings();
                                    }}
                                    disabled={BookingHelper.isImmutable(this.props.selectedBooking.bookingStatus)}
                                >
                                    <Typography sx={{ fontFamily: 'Source Sans Pro', fontWeight: 'bold' }}>
                                        {this.labels.funcScheduleViewUpdateBooking_S}
                                    </Typography>
                                </IbssButton>
                            )}

                            {/* add a new booking, an option to be displayed only on cancelled bookings */}
                            {this.props.selectedBooking?.bookingId && (this.props.selectedBooking?.bookingStatus === "Cancelled" || this.props.selectedBooking?.bookingStatus === "Auto Cancelled") &&
                                <IbssButton
                                    variant="contained"
                                    fullWidth
                                    size={'large'}
                                    sx={{ margin: buttonMargin }}
                                    onClick={() => this.props.clearSelectedBooking()} // this clears the selected booking, to leave just selected Slot and selected space, which simulates a user clicking on an empty slot to create new booking.
                                >
                                    <Typography sx={{ fontFamily: 'Source Sans Pro', fontWeight: 'bold' }}>
                                        {this.labels.funcScheduleAddNewBooking_S}
                                    </Typography>
                                </IbssButton>
                            }
                        </div>
                    </Box>
                    }

                    {this.state.isSwapSpace && this.props.selectedBooking && <SwapSpace
                        open={this.state.isSwapSpace && this.props.open}
                        bookingStart={this.props.selectedBooking?.bookingStart}
                        bookingEnd={this.props.selectedBooking?.bookingEnd}
                        bookingId={this.props.selectedBooking?.bookingId}
                        bookingSpaceId={this.props.selectedBooking.spaceId} // added these for showing linkedSpace names instead of using space property from this.props.selectedSpace 
                        bookingSpaceName={this.props.selectedBooking.spaceName} // added these for showing linkedSpace names instead of using space property from this.props.selectedSpace 
                        cateringOrderId={this.state.cateringOrder?.orderId ?? ''}
                        nodeId={this.props.selectedBooking?.nodeId}
                        buildingId={this.props.buildingId}
                        space={this.props.selectedSpace[0]}
                        setOpen={(val: boolean) => this.setState({ isSwapSpace: val })}
                        patchBooking={this.patchBooking.bind(this)}
                        updateBooking={this.updateBooking.bind(this)}
                        showBookingCriteria={(val: boolean) => this.props.setOpen(val)}
                        getBookings={async () => await this.props.getBookings()}
                    />
                    }

                    {this.state.isSwapDateTime && this.props.selectedBooking && <SwapDateTime
                        open={this.state.isSwapDateTime && this.props.open}
                        bookingStart={DateTime.fromJSDate(this.state.start).toISO()}
                        bookingEnd={DateTime.fromJSDate(this.state.end).toISO()}
                        bookingId={this.props.selectedBooking?.bookingId}
                        bookingSpaceId={this.props.selectedBooking.spaceId} // added these for showing linkedSpace names instead of using space property from this.props.selectedSpace 
                        cateringOrderId={this.state.cateringOrder?.orderId ?? ''}
                        nodeId={this.props.selectedBooking?.nodeId}
                        bookingSlotsPolicy={this.state.bookingSlotsPolicy}
                        startIntervalMinutes={this.state.startIntervalMinutes}
                        endIntervalMinutes={this.state.endIntervalMinutes}
                        buildingId={this.props.buildingId}
                        space={this.props.selectedSpace[0]}
                        setOpen={(val: boolean) => this.setState({ isSwapDateTime: val })}
                        patchBooking={this.patchBooking.bind(this)}
                        updateBooking={this.updateBooking.bind(this)}
                        showBookingCriteria={(val: boolean) => this.props.setOpen(val)}
                        getBookings={this.props.getBookings.bind(this)}
                    />
                    }

                </IbssDrawer>
                <ConfirmBookingDialog
                    show={this.state.showCateringAidsModal}
                    onReturnHomeClicked={() => this.redirectClicked("Home")}
                    onMakeAnotherBookingClicked={() => this.redirectClicked("AnotherBooking")}
                    isNewBooking={false}
                    cateringReqs={this.props.selectedSpace[0]?.cateringReqs}
                    hearingAidReqs={this.props.selectedSpace[0]?.hearingAidReqs}
                    presentationAidReqs={this.props.selectedSpace[0]?.presentationAidReqs}
                    bookingName={this.state.name}
                    buildingId={this.props.buildingId}
                    spaceName={this.props.selectedSpace[0]?.spaceName}
                    bookingId={this.state.bookingResponse?.Booking_Id ?? ""}
                    spaceId={this.state.bookingResponse?.Space_Id ?? ""}
                    floorId={this.state.bookingResponse?.Node_Id ?? 0}
                    bookingStart={DateTime.fromJSDate(this.state.start).toISO()}
                    bookingEnd={DateTime.fromJSDate(this.state.end).toISO()}
                />
                <ChangeBookingDetailsDialog
                    show={this.state.showCancelBookingModal}
                    isCancelling={true}
                    displayLocation={IBookingDetailsApplication.Onelens}
                    buildingId={this.props.buildingId}
                    nodeId={this.props.selectedSpace[0]?.floorId ?? 0}
                    spaceId={this.props.selectedSpace[0]?.spaceId ?? ''}
                    updateBooking={()=> this.cancelBooking()}
                    bookingStart={this.state.start}
                    bookingEnd={this.state.end}
                    originalBookingStart={this.state.start}
                    cateringOrderId={this.state.cateringOrder?.orderId}
                    onClose={() => this.setState({ showCancelBookingModal: false })}
                />
            </>
        )
    }
}

export default BookingCriteria;

export interface IProps extends RouteComponentProps<IQueryParams>
{
    buildingId: number,
    open: boolean,
    setOpen: (status: boolean) => void,
    getBookings: () => Promise<void>,
    layoutStyle: string; // standard or scheduleViewEdit
    selectedBooking: BookingView | undefined,
    clearSelectedBooking: () => void,
    selectedSlot: ISelectedSlot | null,
    selectedSpace: IMeetingSpace[],
    unsetSelectedCell: () => void,
}

export interface IState
{
    lightModeTheme: boolean,
    name: string,
    description: string,
    onlineMeetingLink: boolean,
    bookingDate: Date,
    start: Date,
    end: Date,

    autoCheckin: boolean,
    onBehalfOf: string,
    onBehalfOfData: IOnBehalfOf,
    bookingParties: IAttendee[],
    bookingOwnerName: string,
    bookingPartyVisitors: IAttendee[],
    costCodes: CostCodeWithAllocation[],

    isStartValid: boolean,
    isEndValid: boolean,
    timeDifference: string,

    bookingSlotsPolicy: IBookingSlots,
    startIntervalMinutes: string[],
    endIntervalMinutes: string[],

    bookingResponse: ICreateBookingResponse | null,
    showCateringAidsModal: boolean,
    showCancelBookingModal: boolean,

    selectedSeatingArrangement: ISpaceArrangement,
    selectedLayoutSpaceId: string,
    selectedLayoutName: string,
    selectedLayoutStyle: string,

    isSwapSpace: boolean,
    isSwapDateTime: boolean,

    cateringItemsTotal: number,
    cateringOrder: CateringOrderView,

    useOnBehalfOfCostCodes: number;
    bookingAttendance: number;

    hearingAidTotal: number;
    presentationAidTotal: number;
    bookingEquipment: IBookingResources[];
}

export interface IMeetingSpace
{
    spaceId: string,
    floorId: number,
    spaceName: string,
    spaceType: string,
    spaceWorkType: string,
    capacity: string,
    zone: string,
    cateringReqs: number,
    presentationAidReqs: number,
    hearingAidReqs: number,
    requiresAV: number,
    spaceLayout: string,
    spaceSetup: number,
    spaceTypeLabel: string,
    bookingPolicyId: string,
    meetingLinkAvailable: number
}

export interface IQueryParams
{
    buildingid: string;
}

export class CateringOrderView
{
    public orderId: string;
    public cateringStatus: string;
    public bookingName: string;
    public createdBy: string;
    public spaceName: string;
    public cateringServiceTime: DateTime;
    public cateringClearingTime: DateTime;
    public menuItems: Array<IMenuItem>

    constructor(value: ICateringOrders)
    {
        this.orderId = value.Order_Id;
        this.cateringStatus = value.Catering_Status;
        this.bookingName = value.Booking_Name;
        this.createdBy = value.CreatedBy;
        this.spaceName = value.Space_Name;
        this.cateringServiceTime = value.Catering_Service_Time;
        this.cateringClearingTime = value.Catering_Clearing_Time;
        this.menuItems = value.Menu_Items;
    }
}

class DeleteBookingService
{
    public async execute(nodeId: number, bookingId: string, hasV2Rights: boolean): Promise<IDeleteBookingResponse>
    {
        if(hasV2Rights)
        {
            return await appContext().ibssApiClientV2.v2.byNodeid.bookings.byBookingid.cancel.patch<IDeleteBookingResponse>({
                nodeId: nodeId,
                bookingid: bookingId,
            });
        }
        else
        {
            return await appContext().ibssApiClientV1.v1.byNodeid.bookings.byBookingid.delete<IDeleteBookingResponse>({
                nodeId: nodeId,
                bookingid: bookingId,
            });
        }
    }
}

interface IDeleteBookingResponse
{
    Details: [];
    Message: string;
}