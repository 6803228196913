import { Component } from 'react';
import { Box } from '@mui/material';
import { appContext } from '../../../AppContext';
import Helper from '../../../Common/Helper';
import { DateTime } from 'luxon';
import IbssDialog from '../BaseDialog/IbssDialog';
import { Typography } from '@mui/material';
import IbssButton from '../../Buttons/Button/IbssButton';

class ChangeBookingDetailsDialog extends Component<IProps, IState>
{
    private get apiClient() { return appContext().apiClient; }
    private helper = new Helper();
    private get labels(){ return appContext().labels; }
    private get local() { return appContext().localStorageProvider; }
    
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            message: '',
            scenario: '',
        };
    }

    public componentDidMount(): void
    {
        this.manageChangeBookingDetailsModal();
    }

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>): void 
    {
        if(this.props.bookingStart !== prevProps.bookingStart && this.props.bookingStart !== this.props.originalBookingStart)
        {
            this.manageChangeBookingDetailsModal();
        }

        if(this.props.isCancelling !== prevProps.isCancelling)
        {
            this.manageChangeBookingDetailsModal();
        }
    }

    private get cateringCutOffTime(): DateTime
    {
        const rootNode = this.local.getNodeData();
        const building = this.helper.getBuildingById(rootNode, this.props.buildingId);
        const cateringCutOffTimeRaw = building?.Pre_Book_Cat_Time ?? '';
        
        const oldBookingStart = DateTime.fromJSDate(this.props.originalBookingStart).setZoneByNode(this.props.buildingId);
        const cutOffTimeDays = cateringCutOffTimeRaw.split('.')[0];
        const cutOffTimeOfDay = cateringCutOffTimeRaw.split('.')[1]?.split(':');
        const cateringCutOffTime = cutOffTimeOfDay && oldBookingStart.date().plus({ days: -cutOffTimeDays }).set({ hour: parseInt(cutOffTimeOfDay[0]), minute: parseInt(cutOffTimeOfDay[1]) });
        return cateringCutOffTime
    }

    private manageChangeBookingDetailsModal(): void
    {
        const newBookingStart = DateTime.fromJSDate(this.props.bookingStart).setZoneByNode(this.props.buildingId);
        const oldBookingStart = DateTime.fromJSDate(this.props.originalBookingStart).setZoneByNode(this.props.buildingId);

        const cateringCutOffTime = this.cateringCutOffTime;
        const buildingNow = DateTime.now().setZoneByNode(this.props.buildingId);

        if(newBookingStart === oldBookingStart && !this.props.isCancelling)
        {
            // if starting time has not changed AND booking is not being cancelled, return;
            return;
        }
        
        const { displayLocation } = this.props;

        if((buildingNow < cateringCutOffTime))
        {
            if(this.props.isCancelling)
            {
                // Scenario 3. Cancelling, Pre-Cut Off;
                const scenario = `${displayLocation}.Cancelling.PreCateringCutOff`;
                this.setState({ message: this.getWarningMessage(scenario),  scenario: scenario });
                return;
            }
            if(newBookingStart.day === oldBookingStart.day)
            {
                // Scenario 1. Booking Time change, Pre-Cut Off;
                const scenario = `${displayLocation}.BookingTime.PreCateringCutOff`;
                this.setState({ message: this.getWarningMessage(scenario),  scenario: scenario });
            }
            else if(newBookingStart.day !== oldBookingStart.day)
            {
                // Scenario 2. Booking Date change, Pre-Cut Off
                const scenario = `${displayLocation}.BookingDate.PreCateringCutOff`;
                this.setState({ message: this.getWarningMessage(scenario),  scenario: scenario });
            }
        }
        else if((buildingNow >= cateringCutOffTime))
        {   
            if(this.props.isCancelling)
            {
                // Scenario 3. Cancelling, Post-Cut Off;
                const scenario = `${displayLocation}.Cancelling.PostCateringCutOff`;
                this.setState({ message: this.getWarningMessage(scenario),  scenario: scenario });
                return;
            }
            if(newBookingStart.day === oldBookingStart.day)
            {
                // Scenario 1. Booking Time change, Post-Cut Off
                const scenario = `${displayLocation}.BookingTime.PostCateringCutOff`;
                this.setState({ message: this.getWarningMessage(scenario), scenario: scenario });
            }
            else if(newBookingStart.day !== oldBookingStart.day)
            {
                // Scenario 2. Booking Date change, Post-Cut Off
                const scenario = `${displayLocation}.BookingDate.PostCateringCutOff`;
                this.setState({ message: this.getWarningMessage(scenario),  scenario: scenario });
            }
        }
        else
        {
            return;
        }
        
    }

    private async cancelCateringOrders(cateringCutOffStatus: ICateringCutOffStatus): Promise<void>
    {

        if(!this.props.nodeId)
        {
            return;
        }

        const cateringCancelPayload =
        {
            Catering_Status: cateringCutOffStatus === 'PreCutOff' ? 'CancelledNoCharge' : 'CancelledCharged',
        };

        if(!this.props.cateringOrderId)
        {
            return;
        }
        
        try
        {
            await this.apiClient.cateringOrders.patchCateringOrder(this.props.nodeId, this.props.cateringOrderId, cateringCancelPayload);
        }
        catch
        {
            return;
        }
    }
    
    private async pendingApprovalCateringOrders(): Promise<void>
    {
        if(!this.props.nodeId)
        {
            return;
        }

        const cateringPatchPayload =
        {
            Catering_Status: 'PendingApproval'
        };

        if(!this.props.cateringOrderId)
        {
            return;
        }

        try
        {
            this.apiClient.cateringOrders.patchCateringOrder(this.props.nodeId, this.props.cateringOrderId, cateringPatchPayload);
        }
        catch
        {
            return;
        }
    }

    private get proceedButton(): JSX.Element
    {
        const { displayLocation } = this.props;

        switch(this.state.scenario)
        {
            case 'FLEX.BookingTime.PreCateringCutOff':
                return (
                        <IbssButton
                            variant="contained"
                            onClick={() => this.proceedWithUpdate()}
                        >
                            {this.labels.funcBookingsYesProceed_S}
                        </IbssButton>
                    )
            
            case 'FLEX.BookingTime.PostCateringCutOff':
                return (
                        <IbssButton
                            variant="contained"
                            color='error'
                            onClick={() => this.proceedWithUpdate()}
                        >
                            {this.labels.funcBookingsYesProceed_S}
                        </IbssButton>
                    )

            case 'FLEX.BookingDate.PreCateringCutOff':
                return (
                    <IbssButton
                        variant="contained"
                        onClick={() => this.proceedWithUpdate()}
                    >
                        {this.labels.funcBookingsYesProceed_S}
                    </IbssButton>
                )

            case 'FLEX.BookingDate.PostCateringCutOff':
                return (
                    <IbssButton
                        variant="contained"
                        color='error'
                        onClick={() => this.proceedWithUpdate()}
                    >
                        {this.labels.funcBookingsYesProceed_S}
                    </IbssButton>
                )
            case `${displayLocation}.Cancelling.PreCateringCutOff`:
            case `${displayLocation}.Cancelling.PostCateringCutOff`:
                return (
                    <IbssButton
                        variant="contained"
                        color='error'
                        onClick={() => this.proceedWithUpdate()}
                    >
                        {this.labels.funcBookingsYesProceed_S}
                    </IbssButton>
                );
            
            default:
                return (
                    <></>
                ) 
        }
    }

    private getWarningMessage(scenario: string): string
    {
        const { displayLocation } = this.props;

        switch(scenario)
        {
            case 'FLEX.BookingTime.PreCateringCutOff':
                return this.labels.funcFlexBookingTimeChangePreCutOff_D;
            
            case 'FLEX.BookingTime.PostCateringCutOff':
                return this.labels.funcFlexBookingTimeChangePostCutOff_D;
            
            case 'FLEX.BookingDate.PreCateringCutOff':
                return this.labels.funcFlexBookingDateChangePreCutOff_D;
            
            case 'FLEX.BookingDate.PostCateringCutOff':
                return this.labels.funcFlexBookingDateChangePostCutOff_D;
            
            case `${displayLocation}.Cancelling.PreCateringCutOff`:
            case `${displayLocation}.Cancelling.PostCateringCutOff`:
                return this.labels.funcBookingScheduleCancelSubtitle_L;
                
            default:
                return '';
        }
    }

    private getCateringUpdateFn(scenario: string): ()=> Promise<void>
    {
        switch(scenario)
        {
            case 'FLEX.BookingTime.PreCateringCutOff':
                return ()=> this.pendingApprovalCateringOrders();

            case 'FLEX.BookingTime.PostCateringCutOff':
                return ()=> this.cancelCateringOrders(ICateringCutOffStatus.PostCutOff);

            case 'FLEX.BookingDate.PreCateringCutOff':
                return ()=> this.cancelCateringOrders(ICateringCutOffStatus.PreCutOff);

            case 'FLEX.BookingDate.PostCateringCutOff':
                return ()=> this.cancelCateringOrders(ICateringCutOffStatus.PostCutOff);

            case 'FLEX.Cancelling.PreCateringCutOff':
            case 'ONELENS.Cancelling.PreCateringCutOff':
                return ()=> this.cancelCateringOrders(ICateringCutOffStatus.PreCutOff);

            case 'FLEX.Cancelling.PostCateringCutOff':
            case 'ONELENS.Cancelling.PostCateringCutOff':
                return ()=> this.cancelCateringOrders(ICateringCutOffStatus.PostCutOff);
                
            default:
                return ()=> Promise.resolve();
        }
    }

    private async proceedWithUpdate(): Promise<void>
    {
        const updateCatering =  this.getCateringUpdateFn(this.state.scenario);
        await updateCatering();
        await this.props.updateBooking();
        this.props.onClose();
    }

    public render(): JSX.Element
    {
        return (
            <IbssDialog
                aria-modal="true"
                aria-label="change booking time warning modal"
                fullWidth
                maxWidth={'md'}
                open={this.props.show}
                onClose={this.props.onClose}
                header=
                {
                    <>
                        <label className="modal-heading">{this.labels.HubLabelWarning}</label>
                    </>
                }
                dialogContent=
                {
                    <Box>
                        <Typography>{this.state.message}</Typography>
                    </Box>
                }
                footer=
                {
                    <div className='d-flex w-90' style={{ justifyContent: 'center', gap: '16px'}}>
                        <IbssButton
                            variant="contained"
                            color='secondary'
                            onClick={()=> this.props.onClose()}
                        >
                            {this.labels.funcBookingScheduleCancelNo_S}
                        </IbssButton>
                        {this.proceedButton}
                    </div>
                }
            />
        )
    }
}

export default ChangeBookingDetailsDialog;

export interface IProps
{
    show: boolean;
    // handleShowPopup: (val: boolean, isCancelling: boolean) => void;
    isCancelling: boolean;
    displayLocation: IBookingDetailsApplication;
    onClose: ()=> void;
    buildingId: number;
    nodeId: number;
    spaceId: string;
    updateBooking: ()=> Promise<void>;
    bookingStart: Date;
    bookingEnd: Date;
    originalBookingStart: Date;
    cateringOrderId?: string;
    bookingId?: string;
}

export interface IState
{
    message: string;
    scenario: string;
}

enum ICateringCutOffStatus
{
    PreCutOff='PreCutOff',
    PostCutOff='PostCutOff'
}

export enum IBookingDetailsApplication
{
    Flex='FLEX',
    Onelens='ONELENS',
}