import { Component } from 'react';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import { Box, Card, Skeleton, TextField, Typography } from '@mui/material';
import { GetCateringStatus } from '../../../OneLens/CateringOrders/List/CateringStatus'; // todo: shouldn't be referncing different page
import { appContext } from '../../../../AppContext';
import IbssTimePicker from '../../../../Components/Inputs/TimePicker/IbssTimePicker';
import { DateTime } from 'luxon';
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { Icons } from '../../../../Common/AllsvgIcons';
import { CateringMenuItemView, ICateringRestrictionView } from './EditCateringOrder';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IbssInputDropDown from '../../../../Components/Inputs/SelectList/IbssInputDropDown';

class OrderSummaryPanel extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get appState() { return appContext().state; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            orderItemsExpanded: false
        };
    };

    private getSelectedRestrictionNames(): string
    {
        const selectedRestrictionIds = this.props.selectedRestrictions.map(x => x.id)
        const selectedRestrictions = this.props.cateringRestrictions.filter(x => selectedRestrictionIds.includes(x.id));
        return selectedRestrictions.map(x => x.name).toString();
    }

    public render(): JSX.Element
    {
        const cateringStatus = new GetCateringStatus({});

        const cutOffTimeDays = this.props.preBookCateringTime.split('.')[0];
        const cutOffTimeOfDay = this.props.preBookCateringTime.split('.')[1]?.split(':');
        const cutOffTime = cutOffTimeOfDay && this.props.bookingStart.date().plus({ days: -cutOffTimeDays }).set({ hour: parseInt(cutOffTimeOfDay[0]), minute: parseInt(cutOffTimeOfDay[1]) });
        const cutOffDuration = cutOffTime && cutOffTime.diff(DateTime.now().offsetTimeByNode(this.props.buildingId), ['days', 'hours', 'minutes']).toObject();
        const pastCutOffPeriod = cutOffDuration ? (cutOffDuration.days ?? 1) < 1 && (cutOffDuration.hours ?? 1) < 1 && (cutOffDuration.minutes ?? 1) < 1 : false;

        const selectedServiceTimeError = (this.props.cateringServiceTime < this.props.bookingStart) || (this.props.cateringServiceTime > this.props.bookingEnd);
        const selectedCleanUpTimeError = (this.props.cateringClearingTime < this.props.bookingStart) || (this.props.cateringClearingTime > this.props.bookingEnd);
        const serviceTimeGreaterThanClearUpTime = (this.props.cateringServiceTime) > this.props.cateringClearingTime;

        return (
            <Card>
                {
                    this.props.skeletonLoad ?
                        <SummaryCardSkeleton />
                        :
                        <Box sx={{ p: 4 }}>
                            <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                                <Typography variant="h6" gutterBottom>{this.props.newOrder ? this.labels.funcEditCateringOrderNewOrder_S : ''} {this.labels.HublabelSummary}</Typography>
                                {
                                    !this.props.newOrder &&
                                    <div>{cateringStatus.renderTaskOverAllStatus(this.props.cateringStatus)}</div>
                                }
                            </div>
                            <IbssInputDropDown id={'menusDropdown'} value={this.props.selectedMenu} options={this.props.availableMenus} onChange={(e: { target: { value: string; }; }) => this.props.menuChanged(e.target.value)} />
                            <Box component="div" sx={{ marginTop: '16px', display: 'flex', justifyContent: 'space-around' }}>
                                <Box style={{ alignSelf: 'center' }}>
                                    <div style={{ fontSize: '20px' }}>
                                        <IbssTimePicker
                                            label={this.labels.HubLabelService}
                                            className='ibss-timepicker'
                                            value={this.props.cateringServiceTime}
                                            onChange={e => e !== null ? this.props.onCateringServiceTimeChange(new Date(e.toJSDate())) : {}}
                                            ampm={false}
                                            minutesStep={1}
                                            disabled={!this.props.isOneLens && pastCutOffPeriod}
                                            slotProps={{ textField: { error: selectedServiceTimeError || serviceTimeGreaterThanClearUpTime || !this.props.cateringServiceTime, fullWidth: true } }}
                                        />
                                    </div>
                                </Box>
                                <div
                                    className="icon-text-inline"
                                    style={{
                                        padding: "10px 22px",
                                    }}>
                                    <img src={`/images/Sidebar_Icons/${this.appState.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 239.svg`} alt="" />
                                </div>
                                <div style={{ alignSelf: 'center' }}>
                                    <div style={{ fontSize: '23px' }}>
                                        <IbssTimePicker
                                            label={this.labels.HubLabelCleanUp}
                                            className='ibss-timepicker'
                                            value={this.props.cateringClearingTime}
                                            onChange={e => e !== null ? this.props.onCateringClearingTimeChange(new Date(e.toJSDate())) : {}}
                                            ampm={false}
                                            minutesStep={1}
                                            slotProps={{ textField: { error: selectedCleanUpTimeError || serviceTimeGreaterThanClearUpTime || !this.props.cateringClearingTime, fullWidth: true } }}
                                            disabled={!this.props.isOneLens && pastCutOffPeriod}
                                        />
                                    </div>
                                </div>
                            </Box>
                            <div className="d-flex">
                                <IbssSvgIcon fontSize="small" className="mr-1">{Icons.TimerIcon}</IbssSvgIcon>
                                <Typography gutterBottom>
                                    {`${this.labels.HubLabelCutOffTime}: ${!pastCutOffPeriod ? cutOffDuration?.days : 0} ${this.labels.HubLabelDays}, ${!pastCutOffPeriod ? cutOffDuration?.hours : 0} ${this.labels.HubLabelHours}, ${!pastCutOffPeriod ? Math.round(cutOffDuration?.minutes || 0) : 0} ${this.labels.HubLabelMinutes}`}
                                </Typography>
                            </div>
                            <div>
                                <TextField
                                    id="outlined-multiline-static"
                                    multiline
                                    disabled={!this.props.isOneLens && pastCutOffPeriod}
                                    color="secondary"
                                    placeholder={this.labels.HubLabelAddNotes}
                                    value={this.props.cateringNotes}
                                    rows={4}
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                    fullWidth
                                    onChange={e => this.props.onNotesChange(e.target.value)}
                                />
                            </div>
                            <div className='mt-1' style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <div className="mr-3">{this.labels.funcCateringOrderAllergies_S}:</div>
                                <div style={{ cursor: 'pointer', color: '#4380cb', display: 'flex', flexWrap: 'wrap' }} onClick={() => this.props.displayRestrictionsModal()}>{this.props.selectedRestrictions.length == 0 ? `${this.labels.HubButtonAdd} +` : this.getSelectedRestrictionNames()}</div>
                            </div>
                            <Box className="mt-1" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography variant="overline" fontSize={14} display="block" gutterBottom>{`${this.props.orderItems?.map(x => x.quantity).reduce((x, y) => { return x + y }, 0)} ${this.labels.HubLabelItems}`}</Typography>
                                <IbssButton onClick={() => this.setState({ orderItemsExpanded: !this.state.orderItemsExpanded })} endIcon={this.state.orderItemsExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}>{this.labels.HublabelViewAll}</IbssButton>
                            </Box>
                            {
                                this.state.orderItemsExpanded &&
                                <div>
                                    {this.props.orderItems.filter(item => item.quantity > 0).map(item =>
                                    (
                                        <div>{item.name}: {item.quantity}</div>
                                    )
                                    )}
                                </div>
                            }
                            <Box className="mt-1" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography variant="overline" fontSize={14} display="block" gutterBottom>{this.labels.funcEditCateringOrderOrderTotal_S}:</Typography>
                                <Typography variant="h6" display="block" gutterBottom>{`${this.props.currency}${this.props.orderItems?.map(x => x.unitPrice * x.quantity).reduce((x, y) => { return x + y }, 0).toFixed(2)}`}</Typography>
                            </Box>
                            <div className='d-flex justify-content-between'>
                                <IbssButton color="secondary" variant="contained" onClick={() => this.props.cancelClicked()}>{this.labels.HubLabelCancelChanges}</IbssButton>
                                <IbssButton disabled={selectedServiceTimeError || selectedCleanUpTimeError || serviceTimeGreaterThanClearUpTime || (!this.props.isOneLens && pastCutOffPeriod) || !this.props.cateringServiceTime || !this.props.cateringClearingTime} variant="contained" onClick={() => this.props.newOrder ? this.props.createClicked() : this.props.updateClicked()}>{this.props.newOrder ? this.labels.HubLabelSaveChanges : this.labels.funcEditCateringOrderConfirmChanges_S}</IbssButton>
                            </div>
                        </Box>
                }

            </Card>
        )
    }
}

export default OrderSummaryPanel;

export interface IProps
{
    bookingName: string;
    bookingStart: DateTime;
    bookingEnd: DateTime;
    preBookCateringTime: string;
    buildingId: number;
    cateringServiceTime: DateTime;
    cateringClearingTime: DateTime;
    skeletonLoad: boolean;
    newOrder: boolean
    cateringStatus: string;
    onCateringServiceTimeChange: (date: Date) => void;
    onCateringClearingTimeChange: (date: Date) => void;
    onNotesChange: (value: string) => void;
    isOneLens: boolean;
    cateringNotes: string;
    orderItems: CateringMenuItemView[];
    currency: string;
    displayRestrictionsModal: () => void;
    selectedRestrictions: ICateringRestrictionView[];
    cateringRestrictions: ICateringRestrictionView[];
    createClicked: () => void;
    cancelClicked: () => void;
    updateClicked: () => void;
    availableMenus: IDropdownOptions[];
    menuChanged: (value: string) => void;
    selectedMenu: string;
}

export interface IState
{
    orderItemsExpanded: boolean;
}

export class SummaryCardSkeleton extends Component
{
    public render(): JSX.Element
    {
        return (
            <div style={{ width: '100%', padding: '10px', height: '450px' }}>
                <Skeleton sx={{ margin: '20px 0px' }} variant="rectangular" width={'50%'} height={10} />
                <div style={{ textAlign: 'center' }}>
                    <Skeleton variant="rectangular" width={'95%'} height={100} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '10px' }}>
                    <Skeleton sx={{ margin: '5px 5px' }} variant="rectangular" width={'35%'} height={10} />
                    <Skeleton sx={{ margin: '5px 5px' }} variant="rectangular" width={'35%'} height={10} />
                </div>
                <Skeleton sx={{ margin: '20px 0px' }} variant="rectangular" width={'70%'} height={10} />
                <div style={{ textAlign: 'center' }}>
                    <Skeleton variant="rectangular" width={'95%'} height={70} />
                </div>
                <div style={{ display: 'flex', marginTop: '30px' }}>
                    <Skeleton sx={{ margin: '5px 5px' }} variant="rectangular" width={'15%'} height={10} />
                    <Skeleton sx={{ margin: '5px 5px' }} variant="rectangular" width={'45%'} height={10} />
                </div>
                <div style={{ display: 'flex', marginTop: '10px' }}>
                    <Skeleton sx={{ margin: '5px 5px' }} variant="rectangular" width={'15%'} height={10} />
                    <Skeleton sx={{ margin: '5px 5px' }} variant="rectangular" width={'35%'} height={10} />
                </div>
            </div>
        )
    }
}

export interface IDropdownOptions
{
    label: string;
    value: string;
}